import Warning from "./../images/warning.svg";
import "./../stylesheets/settings.css";
import React, { useState, useEffect, useMemo } from "react";
import Popup from "reactjs-popup";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Settings({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(true);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/user`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();

          setUser(data.user);
          if (data.user?.permissions?.name === "Super Admin") {
            setIsSuperAdmin(true);
          } else {
            setIsSuperAdmin(false);
          }
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUser();
  }, [logtail]);
  const changePasswordButton = () => {
    navigate("/settings/changePassword");
  };
  const [selectedSetting, setSelectedSetting] = useState("account");

  const [deleteUserName, setDeleteUserName] = useState("");

  const handlePhoneInputChange = (event) => {
    setDeleteUserName(event.target.value);

    if (event.target.value.length <= 1) {
      document.querySelector(".delete-phone-error").classList.remove("hidden");
    } else {
      document.querySelector(".delete-phone-error").classList.add("hidden");
    }
  };
  const [saveChanges, setSaveChanges] = useState(false);
  const handleDeactivate = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/deactivate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            status: "deactivated",
          }),
        });
        if (response.status === 200) {
          localStorage.removeItem("vitpay_data");
          navigate("/login");
        }
        setSaveChanges(false);
      }
      setSaveChanges(false);
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const handleDelete = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      setShowSuccess(false);
      setShowError(false);

      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/delete`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            userName: deleteUserName,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          toast.success(data.message);
          setSaveChanges(false);
        } else if (response.status === 400) {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {}
  };
  const handleAdd = async () => {
    navigate("/settings/signup");
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={"Settings"}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <section className="settings">
        <Navbar
          section={"Settings"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />
        <div className="settings__topbar">
          <button
            className={`settings__button ${
              selectedSetting === "account" ? "active" : ""
            }`}
            onClick={() => setSelectedSetting("account")}
          >
            My Account
          </button>
          {/* <button
            className={`settings__button ${
              selectedSetting === "profile" ? "active" : ""
            }`}
            onClick={() => setSelectedSetting("profile")}
          >
            My Profile
          </button> */}
          {/* <button
            className={`settings__button ${
              selectedSetting === "security" ? "active" : ""
            }`}
            onClick={() => setSelectedSetting("security")}
          >
            Security
          </button> */}
          {isSuperAdmin && (
            <button
              className={`settings__button ${
                selectedSetting === "super" ? "active" : ""
              }`}
              onClick={() => setSelectedSetting("super")}
            >
              Super Admin Settings
            </button>
          )}
        </div>
        {selectedSetting === "account" && (
          <div className="settings__option">
            <h3 className="settings__heading">My Account</h3>
            {user && (
              <div className="profile__settings">
                <div className="prifile__detail">
                  <h3>
                    {user.firstName} {user.surName}
                  </h3>
                  <h4>{user.adminOccupation}</h4>
                </div>
                <div className="profile__information">
                  <div className="profile__information__top">
                    <h3>Personal Information</h3>
                  </div>
                  <div className="profile__information__detail">
                    <div className="profile__information__detail__item">
                      <h3>First Name</h3>
                      <h4>{user.firstName}</h4>
                    </div>
                    <div className="profile__information__detail__item">
                      <h3>Last Name</h3>
                      <h4>{user.surName}</h4>
                    </div>
                    <div className="profile__information__detail__item">
                      <h3>Phone Number</h3>
                      <h4>{user.phoneNumber}</h4>
                    </div>
                    <div className="profile__information__detail__item">
                      <h3>Email</h3>
                      <h4>{user.email}</h4>
                    </div>
                    <div className="profile__information__detail__item">
                      <h3>Role</h3>
                      <h4>{user.permissions?.name}</h4>
                    </div>
                  </div>
                </div>

                <div className="account__settings">
                  <div className="account__email account__option">
                    <h3>Phone</h3>
                    <h4>{user.phoneNumber}</h4>
                  </div>
                  <div className="account__password account__option">
                    <h3>Password</h3>
                    <button onClick={changePasswordButton}>
                      Change Password
                    </button>
                  </div>
                  <div className="account__deactivate account__option">
                    <div>
                      <h3>Deactivate Account</h3>
                      <p>This will deactivate your Account.</p>
                    </div>
                    <Popup
                      modal
                      className="deactivate-popup"
                      trigger={<button>Deactivate Account</button>}
                    >
                      {(close) => (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            fill="#000000"
                            viewBox="0 0 256 256"
                            onClick={close}
                          >
                            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                          </svg>
                          <h3>
                            Are you sure you want to deactivate the account?
                          </h3>
                          <div style={{ display: "flex" }}>
                            <button
                              style={{ backgroundColor: "red" }}
                              onClick={handleDeactivate}
                            >
                              {saveChanges ? (
                                <TailSpin
                                  color={"#0C1D58"}
                                  visible={saveChanges}
                                  // css={override}
                                  height="30"
                                  width="50"
                                  radius="1"
                                />
                              ) : (
                                "Deactivate Account"
                              )}
                            </button>
                            <button
                              style={{ backgroundColor: "blue" }}
                              onClick={close}
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      )}
                    </Popup>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {/* {selectedSetting === "profile" && (
          <div className="settings__option">
            
          </div>
        )} */}
        {/* {selectedSetting === "security" && (
          <div className="settings__option">
            <h3 className="settings__heading">Security</h3>
            <div className="security__settings">
              <div className="security__settings__account security__settings__type">
                <h3>Account</h3>
                <div className="security__settings__account__email security__settings__type__option">
                  <h3>Recovery Email</h3>
                  <h3>johndoe@gmail.com</h3>
                  <button>Edit</button>
                </div>
                <div className="security__settings__account__phone security__settings__type__option">
                  <h3>Recovery Phone</h3>
                  <h3>(555) 1234 1234</h3>
                  <button>Edit</button>
                </div>
                <div className="security__settings__account__verification security__settings__type__option">
                  <h3>2-Step Verification</h3>
                  <button>Enable</button>
                </div>
              </div>
              <div className="security__settings__transactions security__settings__type">
                <h3>Transaction</h3>
                <div className="security__settings__transacctions__limit security__settings__type__option">
                  <h3>Transaction Limit</h3>
                  <button>Add a Custom Limit</button>
                </div>
                <div className="security__settings__transacctions__approvals security__settings__type__option">
                  <h3>Transactons Approval</h3>
                  <button>More</button>
                </div>
                <div className="security__settings__transacctions__alerts security__settings__type__option">
                  <h3>Email / SMS Alert</h3>
                  <button>Enable</button>
                </div>
              </div>
              <div className="security__settings__logs security__settings__type">
                <h3>Logs</h3>
                <div className="security__settings__logs__devices security__settings__type__option">
                  <h3>Authorized Devices</h3>
                  <button>More</button>
                </div>
                <div className="security__settings__logs__history security__settings__type__option">
                  <h3>Logs History</h3>
                  <button>More</button>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {isSuperAdmin && selectedSetting === "super" && (
          <div className="settings__option">
            {/* <h3 className="settings__heading"></h3> */}
            <div className="delete__settings">
              <div className="profile__information__top">
                <h3>Delete Account</h3>
              </div>
              <div className="delete__settings__item">
                <h3>Permanently Delete Account</h3>
                <Popup
                  modal
                  className="amount-popup"
                  trigger={<button>Delete Account</button>}
                >
                  {(close) => (
                    <>
                      <h3>
                        Enter User Name
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#000000"
                          viewBox="0 0 256 256"
                          onClick={close}
                        >
                          <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                        </svg>
                      </h3>
                      <div
                        id="error-container"
                        style={{ display: `${showError ? "block" : "none"}` }}
                      ></div>
                      <div
                        id="success-container"
                        style={{ display: `${showSuccess ? "block" : "none"}` }}
                      ></div>
                      <div>
                        <Popup
                          className="warning-popup"
                          trigger={
                            <img
                              src={Warning}
                              alt=""
                              className="delete-phone-error hidden"
                            />
                          }
                          on="hover"
                          position="top center"
                          arrow={false}
                        >
                          Enter Username!
                        </Popup>

                        <input
                          onChange={handlePhoneInputChange}
                          value={deleteUserName}
                        />
                      </div>
                      <button
                        style={{ backgroundColor: "red" }}
                        onClick={handleDelete}
                      >
                        {saveChanges ? (
                          <TailSpin
                            color={"#0C1D58"}
                            visible={saveChanges}
                            // css={override}
                            height="30"
                            width="50"
                            radius="1"
                          />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </>
                  )}
                </Popup>
              </div>
            </div>
            <div className="delete__settings">
              <div className="profile__information__top">
                <h3>Settings</h3>
              </div>
              <div className="delete__settings__item add__settings__item">
                <h3>Add Account</h3>
                <button onClick={handleAdd}>Add Account</button>
              </div>
            </div>
            {/* <div className="profile__information">
            </div> */}
          </div>
        )}
      </section>
      <ToastContainer />
    </>
  );
}

export default Settings;
