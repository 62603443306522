import "./../stylesheets/transactions.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useParams, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Transactions({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();

  const { type } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [loans, setLoans] = useState(null);
  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/loans/${type}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();

          setLoans(data.loans);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAllTransactions();
  }, [type, logtail]);
  useEffect(() => {
    if (data) {
      if (data.loans) {
        setLoans(data.loans);
      }
    }
  }, [data]);
  function convertSnakeCaseToTitleCase(inputString) {
    const words = inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    const titleCaseString = words.join(" ");

    return titleCaseString;
  }

  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [isReport, setIsReport] = useState(false);

  const statusOptions = ["Approved", "Pending", "Rejected", "Overdue", "Paid"];
  const [selectedStatusOption, setSelectedStatusOption] = useState(
    statusOptions[0]
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [minAmount, setMinAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(100);

  const [popupStates, setPopupStates] = useState({
    statusPopup: false,
    // typePopup: false,
  });

  function formatCurrency(value) {
    value = value.replace(/[^0-9.]/g, "");

    const dotCount = value.split(".").length - 1;
    if (dotCount > 1) {
      value = value.substring(0, value.lastIndexOf("."));
    }

    return value;
  }

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };
  const userButton = function (event) {
    navigate(`/loan/${event.target.dataset.id}`);
  };
  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };
  const handleReport = async (e) => {
    e.preventDefault();
    const tokenData = localStorage.getItem("vitpay_data");
    const token = tokenData ? JSON.parse(tokenData).token : null;
    if (token) {
      const url =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_FRONTEND_URL
          : process.env.REACT_APP_LOCAL_URL;
      const response = await fetch(`${url}/admin/loans/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          type: type,
          startDate: startDate,
          endDate: endDate,
        }),
      });
      const data = await response.json();
      console.log(data);
      if (response.status === 200) {
        if (data.url) {
          window.open(data.url, "_blank");
        }
      } else {
      }
    }
  };
  const handleFilter = async (e) => {
    e.preventDefault();
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loans/filter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            type: type,
            status: [`${selectedStatusOption.toLowerCase()}`],
            minAmount: Number(minAmount),
            maxAmount: Number(maxAmount),
            startDate: startDate,
            endDate: endDate,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          if (data.loans) {
            setLoans(data.loans);
            setSaveChanges(false);
          }
          setSaveChanges(false);
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`Loans - ${type}`}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="transactions">
        <Navbar
          section={`Loans - ${convertSnakeCaseToTitleCase(type)}`}
          searchType={`loans`}
          setData={setData}
          type={type}
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <section className="transactions__section">
          <div className="loans__dashboard__buttons">
            <div className="transactions__filter">
              <button
                style={
                  isFilterExpanded
                    ? { backgroundColor: "#516BD5" }
                    : { backgroundColor: "#516BD53D", color: "#516BD5" }
                }
                onClick={() => {
                  setIsReport(false);
                  setIsFilterExpanded(!isFilterExpanded);
                }}
              >
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Filter"
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 256 256"
                  fill={isFilterExpanded ? "white" : "#516BD5"}
                >
                  <path d="M230.6,49.53A15.81,15.81,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.74-72.32.08-.09A15.8,15.8,0,0,0,230.6,49.53ZM40,56h0Zm108.34,72.28A15.92,15.92,0,0,0,144,139.17v55.49L112,216V139.17a15.92,15.92,0,0,0-4.32-10.94L40,56H216Z"></path>
                </svg>
              </button>
            </div>
            <div className="generate_report">
              <button
                onClick={() => {
                  setIsFilterExpanded(false);
                  setIsReport(!isReport);
                }}
              >
                Generate Report
              </button>
            </div>
          </div>
          {isFilterExpanded && (
            <div className="filter">
              <div className="filter_row">
                <div className="filter_container">
                  <h5>Date:</h5>
                  <input
                    type="date"
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                    placeholder="From"
                  />
                  <input
                    type="date"
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                    placeholder="To"
                  />
                </div>
                <div className="filter_container">
                  <h5>Status:</h5>
                  <Popup
                    className="filter_popup"
                    trigger={
                      <div>
                        {selectedStatusOption}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#000000"
                          viewBox="0 0 256 256"
                          style={
                            popupStates.statusPopup
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                        >
                          <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                        </svg>
                      </div>
                    }
                    onOpen={() => {
                      handlePopupOpen("timePopup");
                    }}
                    onClose={() => {
                      handlePopupClose("timePopup");
                    }}
                    open={popupStates.timePopup}
                    arrow={false}
                    position="bottom center"
                  >
                    {(close) => (
                      <>
                        {statusOptions.map((option) => (
                          <button
                            style={
                              option === selectedStatusOption
                                ? { backgroundColor: "#ddd" }
                                : null
                            }
                            onClick={() => {
                              setSelectedStatusOption(option);
                              close();
                            }}
                          >
                            {option}
                          </button>
                        ))}
                      </>
                    )}
                  </Popup>
                </div>
              </div>
              <div className="filter_row">
                <div className="filter_container">
                  <h5>Amount:</h5>
                  <input
                    type="text"
                    value={`K${minAmount}`}
                    onChange={(event) => {
                      setMinAmount(formatCurrency(event.target.value));
                    }}
                    placeholder="Min"
                  />
                  <input
                    type="text"
                    value={`K${maxAmount}`}
                    onChange={(event) => {
                      setMaxAmount(formatCurrency(event.target.value));
                    }}
                    placeholder="Max"
                  />
                </div>
                <button onClick={handleFilter}>Apply Filter</button>
              </div>
            </div>
          )}
          {isReport && (
            <div className="filter">
              <div className="filter_row">
                <div className="filter_container">
                  <h5>Date:</h5>
                  <input
                    type="date"
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                    placeholder="From"
                  />
                  <input
                    type="date"
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                    placeholder="To"
                  />
                </div>

                <button onClick={handleReport}>Generate Report</button>
              </div>
            </div>
          )}

          <div className="transactions__history">
            <div className="transactions__history__wrapper">
              <table className="transactions__history__table">
                <thead className="transactions__history__heading">
                  <tr className="transactions__history__heading--item">
                    <th>ID</th>
                    <th>Date</th>
                    <th>Applied By</th>
                    {/* <th>Receiver</th> */}
                    <th>Additional Loan</th>
                    <th>Amount</th>
                    <th>Loan Type</th>
                    <th>Loan Period</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="transactions__history__items">
                  {loans &&
                    loans.map((loan, index) => {
                      return (
                        <tr className="transactions__history--item">
                          <td>{loan.loanId}</td>
                          <td>
                            {new Date(
                              loan.createdAt.split("T")[0]
                            )?.toLocaleDateString("en-GB")}
                          </td>
                          <td>
                            {loan.user.firstName} {loan.user.surName}
                          </td>
                          {/* <td>
                            {transaction}{" "}
                            {transaction}
                          </td> */}
                          <td>{loan.isAdditionalLoan ? "Yes" : "No"} </td>
                          <td
                            className={`${
                              loan.loanAmount > 0
                                ? "transaction_deposit"
                                : "transaction_withdrawal"
                            }`}
                          >
                            {loan.loanAmount < 0
                              ? loan.loanAmount * -1
                              : loan.loanAmount}
                          </td>
                          <td>{loan.loanType}</td>
                          <td>
                            {loan.loanPeriod} Month
                            {loan.loanPeriod === 1 ? "" : "s"}
                          </td>

                          <td
                            className={`${
                              loan.status === "approved" ||
                              loan.status === "paid"
                                ? "transaction_success"
                                : loan.status === "pending"
                                ? "transaction_pending"
                                : "transaction_failure"
                            }`}
                          >
                            {loan.status.toUpperCase()}
                          </td>
                          <td>
                            {admin &&
                              admin.admin.permissions.permissions.loansView && (
                                <button onClick={userButton} data-id={loan._id}>
                                  View
                                </button>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default Transactions;
