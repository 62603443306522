import {
  Sidebar as SidebarImport,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import cross from "./../images/cross.svg";
import "../stylesheets/sidebar.css";
import Statement from "./../images/statement.svg";
import React, { useEffect } from "react";
import Dashboard from "../images/albums-outline.svg";
// import Agent from "../images/briefcase-outline.svg";
import User from "../images/people-outline.svg";
import Settings from "../images/settings-outline.svg";
import Requests from "../images/git-pull-request-outline.svg";
import Transactions from "../images/cash-outline.svg";
import Fees from "../images/coins.svg";
import Logo from "../images/Logo.svg";
import Admin from "../images/user-plus.svg";
import Analytics from "./../images/analytics.svg";
import Loan from "./../images/loan.svg";
function Sidebar({ selected, sidebar, setSidebar }) {
  useEffect(() => {
    if (selected.startsWith("Loans")) {
      const parent = document.querySelector(".ps-submenu-expand-icon");
      parent.style.backgroundColor = "#4a6cdc";
      const firstChild = parent.firstElementChild;

      if (firstChild) {
        firstChild.style.opacity = 0;
      }
    }
  }, [selected]);

  return (
    <>
      <div className="sidebar_container_main">
        <div className="sidebar__inner">
          <Link to="/dashboard">
            <img className="sidebar_logo" src={Logo} alt="logo"></img>
          </Link>
          <SidebarImport
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                width: "100%",
              },
            }}
          >
            <Menu
              menuItemStyles={{
                SubMenuExpandIcon: {
                  color: "#c7c7c7",
                },
                subMenuContent: ({ level }) => ({
                  backgroundColor: "rgba(0,0,0,0.3)",
                  borderRadius: "10px",
                }),

                button: {
                  "&:hover": {
                    backgroundColor: "#4a6cdc",
                  },
                },
              }}
            >
              <MenuItem
                className={`${
                  selected === "Dashboard" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/dashboard" />}
                icon={<img alt="icon" src={Dashboard} />}
              >
                Dashboard
              </MenuItem>
              <SubMenu
                //   className={`${
                //     selected.startsWith("Transaction History")
                //       ? "sidebar__item__selected"
                //       : ""
                //   }`}
                defaultOpen={true}
                label="Loans"
                icon={<img alt="icon" src={Transactions} />}
              >
                <MenuItem
                  className={`${
                    selected.endsWith("all") ? "sidebar__item__selected" : ""
                  }`}
                  component={<Link to="/loans/all" />}
                >
                  All Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("pending")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/pending" />}
                >
                  Pending Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("reviewed")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/reviewed" />}
                >
                  Reviewed Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("approved")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/approved" />}
                >
                  Approved Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("financed")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/financed" />}
                >
                  Financed Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("rejected")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/rejected" />}
                >
                  Rejected Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("paid") ? "sidebar__item__selected" : ""
                  }`}
                  component={<Link to="/loans/paid" />}
                >
                  Paid Loans
                </MenuItem>
                <MenuItem
                  className={`${
                    selected.endsWith("overdue")
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  component={<Link to="/loans/overdue" />}
                >
                  OverDue Loans
                </MenuItem>
              </SubMenu>
              <MenuItem
                className={`${
                  selected === "Statement" ? "sidebar__item__selected" : ""
                }`}
                onClick={() => {
                  setSidebar(false);
                }}
                component={<Link to="/statement" />}
                icon={<img alt="icon" src={Statement} />}
              >
                Monthly Statement
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Analytics" ? "sidebar__item__selected" : ""
                }`}
                onClick={() => {
                  setSidebar(false);
                }}
                component={<Link to="/analytics" />}
                icon={<img alt="icon" src={Analytics} />}
              >
                Analytics
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Apply" ? "sidebar__item__selected" : ""
                }`}
                onClick={() => {
                  setSidebar(false);
                }}
                component={<Link to="/apply" />}
                icon={<img alt="icon" src={Loan} />}
              >
                Apply For Loan
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Users" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/users" />}
                icon={<img alt="icon" src={User} />}
              >
                Users
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Admins" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/admins" />}
                icon={<img alt="icon" src={Admin} />}
              >
                Admins
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Roles" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/roles" />}
                icon={<img alt="icon" src={Admin} />}
              >
                Roles
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Email" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/email" />}
                icon={<img alt="icon" src={Requests} />}
              >
                Email/SMS Generator
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Fees & Limits" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/fees" />}
                icon={<img alt="icon" src={Fees} />}
              >
                Interests
              </MenuItem>
              <MenuItem
                className={`${
                  selected === "Settings" ? "sidebar__item__selected" : ""
                }`}
                component={<Link to="/settings" />}
                icon={<img alt="icon" src={Settings} />}
              >
                Settings
              </MenuItem>
            </Menu>
          </SidebarImport>
        </div>
      </div>
      {sidebar && (
        <div className="sidebar_container_mobile">
          <img
            className="sidebar-close-button"
            src={cross}
            alt=""
            onClick={() => {
              setSidebar(false);
            }}
          />
          <div className="sidebar__inner">
            <Link
              to="/dashboard"
              onClick={() => {
                setSidebar(false);
              }}
            >
              <img className="sidebar_logo" src={Logo} alt="logo"></img>
            </Link>
            <SidebarImport
              onBackdropClick={() => {
                console.log("hello");
                setSidebar(false);
              }}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  width: "100%",
                },
              }}
            >
              <Menu
                menuItemStyles={{
                  SubMenuExpandIcon: {
                    color: "#c7c7c7",
                  },
                  subMenuContent: ({ level }) => ({
                    backgroundColor: "rgba(0,0,0,0.3)",
                    borderRadius: "10px",
                  }),

                  button: {
                    "&:hover": {
                      backgroundColor: "#4a6cdc",
                    },
                  },
                }}
              >
                <MenuItem
                  className={`${
                    selected === "Dashboard" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/dashboard" />}
                  icon={<img alt="icon" src={Dashboard} />}
                >
                  Dashboard
                </MenuItem>
                <SubMenu
                  //   className={`${
                  //     selected.startsWith("Transaction History")
                  //       ? "sidebar__item__selected"
                  //       : ""
                  //   }`}
                  label="Loans"
                  icon={<img alt="icon" src={Transactions} />}
                >
                  <MenuItem
                    className={`${
                      selected.endsWith("all") ? "sidebar__item__selected" : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/all" />}
                  >
                    All Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("pending")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/pending" />}
                  >
                    Pending Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("reviewed")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/reviewed" />}
                  >
                    Reviewed Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("approved")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/approved" />}
                  >
                    Approved Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("financed")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/financed" />}
                  >
                    Financed Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("rejected")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/rejected" />}
                  >
                    Rejected Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("paid") ? "sidebar__item__selected" : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/paid" />}
                  >
                    Paid Loans
                  </MenuItem>
                  <MenuItem
                    className={`${
                      selected.endsWith("overdue")
                        ? "sidebar__item__selected"
                        : ""
                    }`}
                    onClick={() => {
                      setSidebar(false);
                    }}
                    component={<Link to="/loans/overdue" />}
                  >
                    OverDue Loans
                  </MenuItem>
                </SubMenu>
                <MenuItem
                  className={`${
                    selected === "Statement" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/statement" />}
                  icon={<img alt="icon" src={Statement} />}
                >
                  Monthly Statement
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Analytics" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/analytics" />}
                  icon={<img alt="icon" src={Analytics} />}
                >
                  Analytics
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Users" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/users" />}
                  icon={<img alt="icon" src={User} />}
                >
                  Users
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Admins" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/admins" />}
                  icon={<img alt="icon" src={Admin} />}
                >
                  Admins
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Roles" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/roles" />}
                  icon={<img alt="icon" src={Admin} />}
                >
                  Roles
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Email" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/email" />}
                  icon={<img alt="icon" src={Requests} />}
                >
                  Email/SMS Generator
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Fees & Limits"
                      ? "sidebar__item__selected"
                      : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/fees" />}
                  icon={<img alt="icon" src={Fees} />}
                >
                  Interests
                </MenuItem>
                <MenuItem
                  className={`${
                    selected === "Settings" ? "sidebar__item__selected" : ""
                  }`}
                  onClick={() => {
                    setSidebar(false);
                  }}
                  component={<Link to="/settings" />}
                  icon={<img alt="icon" src={Settings} />}
                >
                  Settings
                </MenuItem>
              </Menu>
            </SidebarImport>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
