// import "./../index.css";
import "./../stylesheets/users.css";

import React, { useState, useEffect, useMemo } from "react";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "reactjs-popup";
import Navbar from "./navbar.js";
import "./../stylesheets/roles.css";
import Sidebar from "./sidebar.js";

import {
  LineChart,
  Line,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
function Analytics({ admin, sidebar, setSidebar }) {
  //   const navigate = useNavigate();

  //   const [roles, setRoles] = useState(null);
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [data, setData] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [saveChanges, setSaveChanges] = useState(false);
  const timeOptions = ["This Week", "This Month", "This Year"];
  const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);
  const [selectedTimeOption1, setSelectedTimeOption1] = useState(
    timeOptions[0]
  );

  //   const typeOptions = ["Revenue", "Transfers", "Deposits", "Withdrawals"];
  //   const [selectedTypeOption, setSelectedTypeOption] = useState(typeOptions[0]);
  const [popupStates, setPopupStates] = useState({
    timePopup: false,
    typePopup: false,
  });

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/profit`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setData(data);
          setLoading(false);
        }
      } catch (error) {
        logtail.error("Error fetching transactions", error);
        logtail.flush();
        toast.error("Error fetching transactions");
        setLoading(false);
      }
    };
    getAllTransactions();
    const getAnalytics = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/analytics`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setAnalytics(data.analytics);
          setLoading(false);
        }
      } catch (error) {
        logtail.error("Error fetching analytics", error);
        logtail.flush();
        toast.error("Error fetching analytics");
        setLoading(false);
      }
    };
    getAnalytics();
  }, [logtail]);
  const handleReport = async (e) => {
    e.preventDefault();
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/generateProfitReport`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          if (data.url) {
            window.open(data.url, "_blank");
            setSaveChanges(false);
          }
          setSaveChanges(false);
        } else {
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error("Error generating profit report", error);
      logtail.flush();
      toast.error("Error generating profit report");
      setSaveChanges(false);
    }
  };
  const monthlyDisbursedAmounts = analytics?.monthlyDisbursedAmounts;
  const totalOutstanding = analytics?.outstandingPerMonthArray;
  const paidOff = analytics?.paidOffArray;
  const loanTypeData = analytics?.loanType;
  const statusData = analytics?.initializedResults;
  const publicService = analytics?.loanDistributionByPublicService;
  const averageAmount = analytics?.monthsArray;
  if (!data && !analytics) {
    return <div>Loading...</div>;
  }
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#AF19FF",
    "#FF0000",
    "#00FF00",
  ];

  const dailyProfits = data.dailyProfits.dailyProfits.map((dp) => ({
    date: new Date(dp.date)?.toLocaleDateString("en-GB"),
    ProcessingFee: parseFloat(dp.processingFee),
    InsuranceFee: parseFloat(dp.insuranceFee),
    InterestFee: parseFloat(dp.interestFee),
    PenaltyFee: parseFloat(dp.penaltyFee),
    Total: parseFloat(dp.total),
  }));

  const weeklyProfits = data.dailyProfits.weeklyProfits.map((wp) => ({
    week: `Week ${wp.weekNumber}`,
    ProcessingFee: parseFloat(wp.processingFee),
    InsuranceFee: parseFloat(wp.insuranceFee),
    InterestFee: parseFloat(wp.interestFee),
    PenaltyFee: parseFloat(wp.penaltyFee),
    Total: parseFloat(wp.total),
  }));

  const monthlyProfits = data.dailyProfits.monthlyProfits.map((mp) => ({
    month: mp.month,
    ProcessingFee: parseFloat(mp.processingFee),
    InsuranceFee: parseFloat(mp.insuranceFee),
    InterestFee: parseFloat(mp.interestFee),
    PenaltyFee: parseFloat(mp.penaltyFee),
    Total: parseFloat(mp.total),
  }));

  const upcomingDailyInstallments = data.upcoming.upcomingDailyInstallments.map(
    (dp) => ({
      date: new Date(dp.date)?.toLocaleDateString("en-GB"),
      Installment: parseFloat(dp.dailyInstallment),
    })
  );

  const upcomingWeeklyInstallments =
    data.upcoming.upcomingWeeklyInstallments.map((wp) => ({
      week: `Week ${wp.weekNumber}`,
      Installment: parseFloat(wp.weeklyInstallment),
    }));

  const upcomingMonthlyInstallments =
    data.upcoming.upcomingMonthlyInstallments.map((mp) => ({
      month: mp.month,
      Installment: parseFloat(mp.monthlyInstallment),
    }));
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`Analytics`}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="transactions">
        <Navbar
          section={`Analytics`}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />
        <section className="analytics">
          <div className="analytics__profit__button">
            <button
              onClick={() => {
                setIsFilterExpanded(!isFilterExpanded);
              }}
              className="analytics__profit__button__btn"
            >
              {saveChanges ? (
                <TailSpin
                  color={"#0C1D58"}
                  visible={saveChanges}
                  // css={override}
                  height="30"
                  width="50"
                  radius="1"
                />
              ) : (
                "Generate Profit Report"
              )}
            </button>
            {isFilterExpanded && (
              <div className="filter">
                <div className="filter_row">
                  <div className="filter_container">
                    <h5>Date:</h5>
                    <input
                      type="date"
                      onChange={(event) => {
                        setStartDate(event.target.value);
                      }}
                      placeholder="From"
                    />
                    <input
                      type="date"
                      onChange={(event) => {
                        setEndDate(event.target.value);
                      }}
                      placeholder="To"
                    />
                  </div>
                  <button onClick={handleReport}>Generate Report</button>
                </div>
              </div>
            )}
          </div>
          <div className="analytics__profit">
            <div className="analytics__profit__div">
              <h2>Profit</h2>

              <Popup
                className="charts__popup"
                trigger={
                  <div className="chart__popup__button chart__popup__button_time">
                    {selectedTimeOption}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#000000"
                      viewBox="0 0 256 256"
                      style={
                        popupStates.timePopup
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                    >
                      <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                    </svg>
                  </div>
                }
                onOpen={() => {
                  handlePopupOpen("timePopup");
                }}
                onClose={() => {
                  handlePopupClose("timePopup");
                }}
                open={popupStates.timePopup}
                arrow={false}
                position="bottom left"
              >
                {(close) => (
                  <>
                    {timeOptions.map((option) => (
                      <button
                        style={
                          option === selectedTimeOption
                            ? { backgroundColor: "#ddd" }
                            : null
                        }
                        onClick={() => {
                          setSelectedTimeOption(option);
                          close();
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </>
                )}
              </Popup>
            </div>
            {selectedTimeOption === "This Week" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={dailyProfits}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Total" fill="#8884d8" />
                  <Bar dataKey="ProcessingFee" fill="#82ca9d" />
                  <Bar dataKey="InsuranceFee" fill="#ffc658" />
                  <Bar dataKey="InterestFee" fill="#ff7300" />
                  <Bar dataKey="PenaltyFee" fill="#00C49F" />
                </BarChart>
              </ResponsiveContainer>
            )}
            {selectedTimeOption === "This Month" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={weeklyProfits}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Total" fill="#8884d8" />
                  <Bar dataKey="ProcessingFee" fill="#82ca9d" />
                  <Bar dataKey="InsuranceFee" fill="#ffc658" />
                  <Bar dataKey="InterestFee" fill="#ff7300" />
                  <Bar dataKey="PenaltyFee" fill="#00C49F" />
                </BarChart>
              </ResponsiveContainer>
            )}
            {selectedTimeOption === "This Year" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={monthlyProfits}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Total" fill="#8884d8" />
                  <Bar dataKey="ProcessingFee" fill="#82ca9d" />
                  <Bar dataKey="InsuranceFee" fill="#ffc658" />
                  <Bar dataKey="InterestFee" fill="#ff7300" />
                  <Bar dataKey="PenaltyFee" fill="#00C49F" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
          <div className="analytics__profit">
            <div className="analytics__profit__div">
              <h2>Upcoming Installments</h2>

              <Popup
                className="charts__popup"
                trigger={
                  <div className="chart__popup__button chart__popup__button_time">
                    {selectedTimeOption1}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#000000"
                      viewBox="0 0 256 256"
                      style={
                        popupStates.typePopup
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" }
                      }
                    >
                      <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                    </svg>
                  </div>
                }
                onOpen={() => {
                  handlePopupOpen("typePopup");
                }}
                onClose={() => {
                  handlePopupClose("typePopup");
                }}
                open={popupStates.typePopup}
                arrow={false}
                position="bottom left"
              >
                {(close) => (
                  <>
                    {timeOptions.map((option) => (
                      <button
                        style={
                          option === selectedTimeOption1
                            ? { backgroundColor: "#ddd" }
                            : null
                        }
                        onClick={() => {
                          setSelectedTimeOption1(option);
                          close();
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </>
                )}
              </Popup>
            </div>
            {selectedTimeOption1 === "This Week" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={upcomingDailyInstallments}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Installment" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            )}
            {selectedTimeOption1 === "This Month" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={upcomingWeeklyInstallments}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="week" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Installment" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            )}
            {selectedTimeOption1 === "This Year" && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={upcomingMonthlyInstallments}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Installment" fill="#ffc658" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
          <div className="analytics__profit">
            <h2>Monthly Disbursed Amounts</h2>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={monthlyDisbursedAmounts}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="total" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Outstanding Loan Amount</h2>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={totalOutstanding}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="totalOutstanding"
                  stroke="#8884d8"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Paid Loan Amount</h2>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={paidOff}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="totalPaidOff" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Loan Type Distribution</h2>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={loanTypeData}
                  dataKey="count"
                  nameKey="loanType"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {loanTypeData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Status Wise Distribution</h2>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={statusData}
                  dataKey="count"
                  nameKey="status"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {statusData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Public Service Distribution</h2>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={publicService}
                  dataKey="count"
                  nameKey="_id"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                  label
                >
                  {publicService?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="analytics__profit">
            <h2>Average Monthly Amount Paid</h2>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={averageAmount}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="average" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}
export default Analytics;
