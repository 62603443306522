import "./../stylesheets/dashboard.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function FeesAndLimits({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [feesAndLimits, setFeesAndLimits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/interest`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setFeesAndLimits(data.interestRates);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getData();
  }, [logtail]);

  const modifyDeposit = (input) => {
    let result, num;
    if (input === "") {
      num = "";
    } else {
      result = parseFloat(input) || 0;
      num = Number(result.toFixed(2));
    }

    setFeesAndLimits((prevFeesAndLimits) => {
      // Create a copy of the previous state
      const updatedFeesAndLimits = { ...prevFeesAndLimits };

      // Modify the keys based on the parameters
      updatedFeesAndLimits["monthlyInterest"] = num;

      return updatedFeesAndLimits;
    });
  };
  const modifyInsurance = (input) => {
    let result, num;
    if (input === "") {
      num = "";
    } else {
      result = parseFloat(input) || 0;
      num = Number(result.toFixed(2));
    }

    setFeesAndLimits((prevFeesAndLimits) => {
      // Create a copy of the previous state
      const updatedFeesAndLimits = { ...prevFeesAndLimits };

      // Modify the keys based on the parameters
      updatedFeesAndLimits["insuranceFee"] = num;

      return updatedFeesAndLimits;
    });
  };
  const processingFee = (input) => {
    let result, num;
    if (input === "") {
      num = "";
    } else {
      result = parseFloat(input) || 0;
      num = Number(result.toFixed(2));
    }

    setFeesAndLimits((prevFeesAndLimits) => {
      // Create a copy of the previous state
      const updatedFeesAndLimits = { ...prevFeesAndLimits };

      // Modify the keys based on the parameters
      updatedFeesAndLimits["processingFee"] = num;

      return updatedFeesAndLimits;
    });
  };
  const overduePenalty = (input) => {
    let result, num;
    if (input === "") {
      num = "";
    } else {
      result = parseFloat(input) || 0;
      num = Number(result.toFixed(2));
    }

    setFeesAndLimits((prevFeesAndLimits) => {
      // Create a copy of the previous state
      const updatedFeesAndLimits = { ...prevFeesAndLimits };

      // Modify the keys based on the parameters
      updatedFeesAndLimits["penaltyAmount"] = num;

      return updatedFeesAndLimits;
    });
  };

  const handelUpdateFees = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/interest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            processingFee: feesAndLimits.processingFee,
            monthlyInterest: feesAndLimits.monthlyInterest,
            insuranceFee: feesAndLimits.insuranceFee,
            penaltyAmount: feesAndLimits.penaltyAmount,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
          setFeesAndLimits(data.interestRates);
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={"Fees & Limits"}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="dashboard">
        <Navbar
          section="Fees & Limits"
          sidebar={sidebar}
          setSidebar={setSidebar}
        />

        {feesAndLimits && (
          <section className="fees__section">
            <div className="dashboard__fees__constants">
              <h3>Interest Rate</h3>
              <div className="dahsboard__daily__limits">
                <div className="daily_limit__constant">
                  <input
                    className="prefixed_input"
                    type="number"
                    onChange={(event) => {
                      modifyDeposit(event.target.value);
                    }}
                    onBlur={() => {
                      if (feesAndLimits.monthlyInterest === "") {
                        modifyDeposit(0);
                      }
                    }}
                    value={feesAndLimits.monthlyInterest}
                  ></input>
                  <span className="postfix_percentage">%</span>
                </div>
              </div>
            </div>
            <div className="dashboard__fees__constants">
              <h3>Insurance Rate</h3>
              <div className="dahsboard__daily__limits">
                <div className="daily_limit__constant">
                  <span className="postfix_percentage">%</span>
                  <input
                    className="prefixed_input"
                    type="number"
                    onChange={(event) => {
                      modifyInsurance(event.target.value);
                    }}
                    onBlur={() => {
                      if (feesAndLimits.insuranceFee === "") {
                        modifyInsurance(0);
                      }
                    }}
                    value={feesAndLimits.insuranceFee}
                  ></input>
                </div>
              </div>
            </div>
            <div className="dashboard__fees__constants">
              <h3>Processing Rate</h3>
              <div className="dahsboard__daily__limits">
                <div className="daily_limit__constant">
                  <span className="postfix_percentage">%</span>
                  <input
                    className="prefixed_input"
                    type="number"
                    onChange={(event) => {
                      processingFee(event.target.value);
                    }}
                    onBlur={() => {
                      if (feesAndLimits.processingFee === "") {
                        processingFee(0);
                      }
                    }}
                    value={feesAndLimits.processingFee}
                  ></input>
                </div>
              </div>
            </div>
            <div className="dashboard__fees__constants">
              <h3>Penalty Rate</h3>
              <div className="dahsboard__daily__limits">
                <div className="daily_limit__constant">
                  <span className="postfix_percentage">%</span>
                  <input
                    className="prefixed_input"
                    type="number"
                    onChange={(event) => {
                      overduePenalty(event.target.value);
                    }}
                    onBlur={() => {
                      if (feesAndLimits.penaltyAmount === "") {
                        overduePenalty(0);
                      }
                    }}
                    value={feesAndLimits.penaltyAmount}
                  ></input>
                </div>
              </div>
            </div>
            {admin && admin?.admin?.permissions?.permissions?.interestRate && (
              <button className="save-fees" onClick={handelUpdateFees}>
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Save Changes"
                )}
              </button>
            )}
          </section>
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default FeesAndLimits;
