import React from "react";
import Select from "react-select";

function DropdownUsers(props) {
  const { users, type, setSelectedOptions, selectedOptions } = props;

  const options = [
    ...users?.map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.surName} (${
        type === "email" ? user.email : user.phoneNumber
      })`,
    })),
  ];

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  return (
    <div className="roles__dropdown__div">
      <Select
        className="roles__dropdown"
        classNamePrefix="roles__dropdown"
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        placeholder="Select..."
      />
      {/* {selectedOptions.length > 0 && (
        <div className="selected-users">
          {selectedOptions.map((option) => (
            <p key={option.value}>{option.label}</p>
          ))}
        </div>
      )} */}
    </div>
  );
}

export default DropdownUsers;
