import "../stylesheets/navbar.css";

import React, { useState, useEffect } from "react";
import Search from "./../images/search-outline.svg";
import user_image from "./../images/person-outline.svg";
import Popup from "reactjs-popup";
import sidebarSVG from "./../images/sidebar.svg";
import { Link, useNavigate } from "react-router-dom";

function Navbar({ section, setData, searchType, type, sidebar, setSidebar }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  // const [balance, setBalance] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        const data = await response.json();
        setUser(data.user);
        // setBalance(data.wallet.balance);
      }
    };
    getUser();
  }, []);
  const [searchInput, setSearchInput] = useState("");
  const handlelogout = async () => {
    localStorage.removeItem("vitpay_data");
    navigate("/login");
  };
  const handleSearch = async (query) => {
    const tokenData = localStorage.getItem("vitpay_data");
    const token = tokenData ? JSON.parse(tokenData).token : null;
    if (token) {
      const url =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_FRONTEND_URL
          : process.env.REACT_APP_LOCAL_URL;
      let body = {};
      body.searchTerm = query;
      if (type) {
        body.type = type;
      }
      const response = await fetch(`${url}/admin/${searchType}/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (response.status === 200) {
        setData(data);
      }
    }
  };
  const handleSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <>
      <section className="navbar__section">
        <div className="navbar__sidebar">
          <button className="navbar__sidebar__button" onClick={handleSidebar}>
            <img alt="side" src={sidebarSVG} />
          </button>
        </div>
        <div className="navbar__inner">
          <div className="navbar__inner__selected__heading">
            <h2>{section}</h2>
          </div>
          <div className="navbar__inner__right">
            {searchType && (
              <div className="navbar__inner__search">
                {/* {section !== "Dashboard" && ( */}
                <div className="navbar__inner__search__input">
                  <input
                    type="text"
                    placeholder={`Search ${section}`}
                    onChange={(event) => {
                      setSearchInput(event.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSearch(event.target.value);
                      }
                    }}
                  />
                  <button>
                    <img
                      src={Search}
                      onClick={() => {
                        handleSearch(searchInput);
                      }}
                      alt="search"
                    ></img>
                  </button>
                </div>
                {/* )} */}
              </div>
            )}
            {/* <Popup
              className="navbar-popup notification-popup"
              trigger={
                <div className="navbar__inner__notifications">
                  <button>
                    <img alt="notifications" src={Notification_Icon}></img>
                  </button>
                </div>
              }
              onOpen={() => {
                // handlePopupOpen("popup3");
                // handleNotifications();
                // setNotificationsOpen(true);
              }}
              onClose={() => {
                // handlePopupClose("popup3");
                // setNotificationsOpen(false);
              }}
              // open={popupStates["popup3"]}
              // onClick={handleNotifications}
              arrow={false}
              position="bottom right"
            >
              <div className="notifications__main">
                <div className="natifications__today notifications__items">
                  <h3>Today</h3>
                  <div className="notification__item">
                    <img alt="" src={Notification_Icon}></img>
                    <h4>Notification</h4>
                    <p>Time</p>
                  </div>
                </div>
                <div className="natifications__yesterday notifications__items">
                  <h3>Yesterday</h3>
                  <div className="notification__item">
                    <img alt="" src={Notification_Icon}></img>
                    <h4>Notification</h4>
                    <p>Time</p>
                  </div>
                </div>
                <div className="natifications__older notifications__items">
                  <h3>Older</h3>
                  <div className="notification__item">
                    <img alt="" src={Notification_Icon}></img>
                    <h4>Notification</h4>
                    <p>Time</p>
                  </div>
                </div>
              </div>
            </Popup>  */}
            <Popup
              className="navbar-popup notification-popup"
              trigger={
                <div className="navbar__inner__user">
                  <button>
                    <img alt="user" src={user_image}></img>
                  </button>
                </div>
              }
              onOpen={() => {
                // handlePopupOpen("popup3");
                // handleNotifications();
                // setNotificationsOpen(true);
              }}
              onClose={() => {
                // handlePopupClose("popup3");
                // setNotificationsOpen(false);
              }}
              // open={popupStates["popup3"]}
              // onClick={handleNotifications}
              arrow={false}
              position="bottom right"
            >
              <div className="profile__popup">
                <div className="profile__popup__name">
                  <img alt="profile" src={user_image}></img>
                  {user && <h3>{user.userName}</h3>}
                </div>
                <div className="profile__popup__items">
                  <div className="profile__popup--item ">
                    <Link to="/settings">
                      <h4>Settings</h4>
                    </Link>
                  </div>

                  <div className="profile__popup--item ">
                    <h4 onClick={handlelogout} className="logout">
                      Logout
                    </h4>
                  </div>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </section>
    </>
  );
}

export default Navbar;
