import React, { useEffect, useState, useMemo } from "react";
import "./../stylesheets/apply.css";
import Navbar from "./navbar";
import { useNavigate } from "react-router-dom";
import Dropdown from "./dropdownMain";
import remove from "./../images/remove.svg";
import documentImage from "./../images/document.svg";
import Popup from "reactjs-popup";
import Warning from "./../images/warning.svg";
import Sidebar from "./sidebar";
import DropdownUsers from "./dropdownUsers.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const Apply = ({ admin, sidebar, setSidebar }) => {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [interest, setInterest] = useState(null);
  const [processingFee, setProcessingFee] = useState(null);
  const [insuranceFee, setInsuranceFee] = useState(null);
  const [total, setTotal] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [duration, setDuration] = useState(null);
  const [identityDocument, setIdentityDocument] = useState(null);
  const [addressDocument, setAddressDocument] = useState(null);
  const [paySlip, setPaySlip] = useState(null);
  const [paySlip1, setPaySlip1] = useState(null);
  const [preApproved, setPreApproved] = useState(null);
  const [loanType, setLoanType] = useState(null);
  const [Public, setPublic] = useState(null);
  //   const [address, setAddress] = useState(null);
  const [income, setIncome] = useState(null);
  const [employed, setEmployed] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const [buttonEnable, setButtonEnable] = useState(true);
  const [showError, setShowError] = useState(false);
  const [existingUser, setExistingUser] = useState("No");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState(null);
  const titleType = [
    { name: "Mr." },
    { name: "Mrs." },
    { name: "Miss." },
    { name: "Ms." },
    { name: "Dr." },
    { name: "Prof." },
  ];
  const validateEmail = (event) => {
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(event.target.value)) {
      document.querySelector(".email-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".email-error").classList.add("hidden");
    }
  };

  function validateFirstName(event) {
    setFirstName(event.target.value);
    const nameRegex = /^[a-zA-Z]+$/;

    if (!nameRegex.test(event.target.value)) {
      document.querySelector(".firstName-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".firstName-error").classList.add("hidden");
    }
  }
  function validateLastName(event) {
    setLastName(event.target.value);
    const nameRegex = /^[a-zA-Z]+$/;

    if (!nameRegex.test(event.target.value)) {
      document.querySelector(".lastName-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".lastName-error").classList.add("hidden");
    }
  }
  function validateMobileNumber(event) {
    setPhone(event.target.value);
    const mobileNumberRegex = /^[0-9]+$/;
    if (!mobileNumberRegex.test(event.target.value)) {
      document.querySelector(".phone-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".phone-error").classList.add("hidden");
    }
  }
  function validateGender(event) {
    setGender(event.target.value);
    const selectedGender = event.target.value;
    if (!selectedGender) {
      document.querySelector(".gender-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".gender-error").classList.add("hidden");
    }
  }

  function validateDateOfBirth(event) {
    setDateOfBirth(event.target.value);
    const dobRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dobRegex.test(event.target.value)) {
      document.querySelector(".date-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".date-error").classList.add("hidden");
    }
  }

  function validateAddressNumber(event) {
    setAddressNumber(event.target.value);
    const minLength = 1; // Change this to your minimum required length
    const maxLength = 100; // Change this to your maximum allowed length
    const addressNumber = event.target.value.trim();

    if (addressNumber.length < minLength || addressNumber.length > maxLength) {
      document.querySelector(".addressNumber-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".addressNumber-error").classList.add("hidden");
    }
  }

  function validateStreetName(event) {
    setStreetName(event.target.value);
    const minLength = 1; // Change this to your minimum required length
    const maxLength = 100; // Change this to your maximum allowed length
    const streetName = event.target.value.trim();

    if (streetName.length < minLength || streetName.length > maxLength) {
      document.querySelector(".street-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".street-error").classList.add("hidden");
    }
  }

  function validateResidentialArea(event) {
    setUserAddress(event.target.value);

    const minLength = 1; // Change this to your minimum required length
    const maxLength = 100; // Change this to your maximum allowed length
    const residentialArea = event.target.value.trim();

    if (
      residentialArea.length < minLength ||
      residentialArea.length > maxLength
    ) {
      document.querySelector(".residential-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".residential-error").classList.add("hidden");
    }
  }

  function validateBankName(event) {
    setBankName(event.target.value);
    const minLength = 1; // Change this to your minimum required length
    const maxLength = 100; // Change this to your maximum allowed length
    const bankName = event.target.value.trim();

    if (bankName.length < minLength || bankName.length > maxLength) {
      document.querySelector(".bank-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".bank-error").classList.add("hidden");
    }
  }

  function validateAccountNumber(event) {
    setAccountNumber(event.target.value);
    const minLength = 1; // Change this to your minimum required length
    const maxLength = 50; // Change this to your maximum allowed length
    const accountNumber = event.target.value.trim();

    if (accountNumber.length < minLength || accountNumber.length > maxLength) {
      document.querySelector(".account-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".account-error").classList.add("hidden");
    }
  }

  function validateNRCNumber(event) {
    setNRCNumber(event.target.value);
    const minLength = 1; // Change this to your minimum required length
    const maxLength = 100; // Change this to your maximum allowed length
    const streetName = event.target.value.trim();

    if (streetName.length < minLength || streetName.length > maxLength) {
      document.querySelector(".nrc-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".nrc-error").classList.add("hidden");
    }
  }

  function isEmailValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isNameValid(name) {
    const nameRegex = /^[a-zA-Z ]+$/;
    return nameRegex.test(name);
  }

  function isMobileNumberValid(mobileNumber) {
    const mobileRegex = /^[0-9]{10,15}$/;
    return mobileRegex.test(mobileNumber);
  }
  function isAddressNumberValid(addressNumber) {
    const addressNumberRegex = /^[a-zA-Z0-9\s#]+$/; // Modify as per your requirements
    return addressNumberRegex.test(addressNumber);
  }

  function isStreetNameValid(streetName) {
    const streetNameRegex = /^[a-zA-Z0-9\s]+$/; // Modify as per your requirements
    return streetNameRegex.test(streetName);
  }

  function isResidentialAreaValid(residentialArea) {
    const residentialAreaRegex = /^[a-zA-Z\s]+$/; // Modify as per your requirements
    return residentialAreaRegex.test(residentialArea);
  }

  function isBankNameValid(bankName) {
    const bankNameRegex = /^[a-zA-Z\s]+$/; // Modify as per your requirements
    return bankNameRegex.test(bankName);
  }

  function isAccountNumberValid(accountNumber) {
    const accountNumberRegex = /^[0-9]+$/; // Modify as per your requirements
    return accountNumberRegex.test(accountNumber);
  }

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [gender, setGender] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [addressNumber, setAddressNumber] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [NRCNumber, setNRCNumber] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  useEffect(() => {
    if (
      isNameValid(firstName) &&
      isNameValid(lastName) &&
      isEmailValid(email) &&
      isMobileNumberValid(phone) &&
      isAddressNumberValid(addressNumber) &&
      isStreetNameValid(streetName) &&
      isResidentialAreaValid(userAddress) &&
      isBankNameValid(bankName) &&
      isAccountNumberValid(accountNumber)
    ) {
      setButtonEnable(false);
      setShowError(false);
    }
  }, [
    firstName,
    lastName,
    email,
    phone,
    userAddress,
    bankName,
    accountNumber,
    addressNumber,
    streetName,
  ]);

  //   useEffect(() => {
  //     if (!user) {
  //       navigate("/login");
  //     }
  //   }, [user, navigate]);
  const [interestRates, setInterestRates] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        if (localStorage.getItem("vitpay_data")) {
          const access_token = JSON.parse(localStorage.getItem("vitpay_data"));
          const token = access_token.token;
          const response = await fetch(`${url}/admin/interest`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setInterestRates(data.interestRates);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUser();
  }, [logtail]);
  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/users`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUsers(data.users);
          setLoading(false);
        }
        setLoading(true);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUsers();
  }, [logtail]);
  const handleRemoveIdentity = () => {
    setIdentityDocument(null);
  };
  const handleRemoveAddress = () => {
    setAddressDocument(null);
  };
  const handleRemovePayslip = () => {
    setPaySlip(null);
  };
  const handleRemovePayslip1 = () => {
    setPaySlip1(null);
  };
  const handleRemovePreApproved = () => {
    setPreApproved(null);
  };
  const handleUploadFileChange = (event) => {
    // document.querySelector(".image-error").classList.add("hidden");
    setIdentityDocument(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".identity__file");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleUploadAddressChange = async (event) => {
    // document.querySelector(".image-error").classList.add("hidden");
    setAddressDocument(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".address__file");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleUploadPaySlip = async (event) => {
    // document.querySelector(".image-error").classList.add("hidden");
    setPaySlip(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".pay__file");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handleUploadPaySlip1 = async (event) => {
    // document.querySelector(".image-error").classList.add("hidden");
    setPaySlip1(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".pay1__file");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };
  const handlePreApproved = async (event) => {
    // document.querySelector(".image-error").classList.add("hidden");
    setPreApproved(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".pre__file");
      img.src = e.target.result;
    };

    reader.readAsDataURL(file);
  };

  const type = [
    { name: "Personal Loan" },
    { name: "SME Loan" },
    { name: "Agricultural Loan" },
    { name: "Educational Loan" },
    { name: " Other" },
  ];
  const publicService = [
    { name: "Not A Public Servant" },
    { name: "Government Departments" },
    { name: "Air Force" },
    { name: "Army" },
    { name: "National Services" },
  ];
  const [saveChanges, setSaveChanges] = useState(false);
  const handleSubmit = async (e) => {
    if (step === 3) {
      try {
        setSaveChanges(true);
        e.preventDefault();
        setButtonEnable(true);
        setShowError(false);
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        if (localStorage.getItem("vitpay_data")) {
          const access_token = JSON.parse(localStorage.getItem("vitpay_data"));
          const token = access_token.token;
          const response = await fetch(`${url}/admin/loan/apply`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            body: JSON.stringify({
              loanType: loanType.label,
              publicService: Public.label,
              loanAmount: parseInt(amount),
              loanPeriod: parseInt(duration),
              employementStatus: employed,
              employer: employer,
              employeeNumber: employeeNumber,
              netMonthlyIncome: parseInt(income),
              addressDuration: address,
              identityDocument: {
                name: "profile",
                extension: identityDocument.type.split("/")[1],
              },
              payslip1: {
                name: "profile",
                extension: paySlip.type.split("/")[1],
              },
              payslip2: {
                name: "profile",
                extension: paySlip1.type.split("/")[1],
              },
              preApprovedLetter: {
                name: "profile",
                extension: preApproved.type.split("/")[1],
              },
              addressProof: {
                name: "profile",
                extension: addressDocument.type.split("/")[1],
              },
              title: title?.label,
              firstName: firstName,
              surName: lastName,
              nationality: "Zambia",
              NRCNumber: NRCNumber,
              gender: gender,
              dateOfBirth: Date(dateOfBirth),
              email: email,
              phoneNumber: phone,
              residentialAddressNumber: addressNumber,
              streetName: streetName,
              residentialArea: userAddress,
              bankName: bankName,
              accountNumber: accountNumber,
              isUser: existingUser === "Yes" ? true : false,
              userId: selectedOptions?.value,
            }),
          });
          const data = await response.json();
          if (response.status === 200) {
            const url = data.uploadUrls;
            var imgIdentity = document.querySelector(".identity__file");

            const res = await fetch(imgIdentity.src);
            const blob = await res.blob();
            const identityUpload = await fetch(url.identityDocument, {
              method: "PUT",
              body: blob,
            });
            var imgpay = document.querySelector(".pay__file");

            const respay = await fetch(imgpay.src);
            const blobPay = await respay.blob();
            const payUpload = await fetch(url.payslip1, {
              method: "PUT",
              body: blobPay,
            });
            var imgpay1 = document.querySelector(".pay1__file");

            const respay1 = await fetch(imgpay1.src);
            const blobpay1 = await respay1.blob();
            const pay1Upload = await fetch(url.payslip2, {
              method: "PUT",
              body: blobpay1,
            });
            var imgpre = document.querySelector(".pre__file");

            const respre = await fetch(imgpre.src);
            const blobpre = await respre.blob();
            const preUpload = await fetch(url.preApprovedLetter, {
              method: "PUT",
              body: blobpre,
            });
            var imgaddress = document.querySelector(".address__file");

            const resaddress = await fetch(imgaddress.src);
            const blobaddress = await resaddress.blob();
            const addressUplaod = await fetch(url.addressProof, {
              method: "PUT",
              body: blobaddress,
            });
            if (
              addressUplaod.status === 200 &&
              preUpload.status === 200 &&
              pay1Upload.status === 200 &&
              payUpload.status === 200 &&
              identityUpload.status === 200
            ) {
              navigate(`/loan/${data.loan._id}`);
            }
            setSaveChanges(false);
          } else if (response.status !== 200) {
            toast.error(data.message);
            setSaveChanges(false);
          }
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setSaveChanges(false);
      }
    } else {
      setStep(step + 1);
    }
  };
  const handleBack = () => {
    if (step === 0) {
    } else {
      setStep(step - 1);
    }
  };
  useEffect(() => {
    if (amount && duration) {
      const interestTemp = parseInt(
        parseInt(amount) * (interestRates.monthlyInterest / 100)
      );
      const processingFeeTemp = parseInt(
        parseInt(amount) * (interestRates.processingFee / 100)
      );
      const insuranceFeeTemp = parseInt(
        parseInt(amount) * (interestRates.insuranceFee / 100)
      );
      setInterest(interestTemp.toFixed(2));
      setProcessingFee(processingFeeTemp.toFixed(2));
      setInsuranceFee(insuranceFeeTemp.toFixed(2));
      const totalTemp =
        parseInt(amount) +
        parseInt(interestTemp) +
        parseInt(processingFeeTemp) +
        parseInt(insuranceFeeTemp);
      setTotal(totalTemp.toFixed(2));
      setMonthly((totalTemp / parseInt(duration)).toFixed(2));
    }
  }, [amount, duration, interestRates]);
  const validateAddress = (e) => {
    setAddress(parseInt(e.target.value));
    if (e.target.value <= 0 || e.target.value > 4) {
      document.querySelector(".address-error")?.classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".address-error")?.classList.add("hidden");
    }
  };
  const validateEmployer = (e) => {
    setEmployer(e.target.value);
    if (e.target.value.length <= 0) {
      document.querySelector(".employer-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".employer-error").classList.add("hidden");
    }
  };
  const validateIncome = (e) => {
    setIncome(e.target.value);
    if (e.target.value <= 0) {
      document.querySelector(".income-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".income-error").classList.add("hidden");
    }
  };
  const validateEmployed = (e) => {
    setEmployed(e.target.value);
    if (e.target.value.length <= 0) {
      document.querySelector(".employed-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".employed-error").classList.add("hidden");
    }
  };
  const validateEmployee = (e) => {
    setEmployeeNumber(e.target.value);
    if (e.target.value.length <= 0) {
      document.querySelector(".employee-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".employee-error").classList.add("hidden");
    }
  };
  const validateDuration = (e) => {
    setDuration(e.target.value);
    if (e.target.value.length <= 0) {
      document.querySelector(".duration-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".duration-error").classList.add("hidden");
    }
  };
  const validateAmount = (e) => {
    setAmount(e.target.value);
    if (e.target.value.length <= 0) {
      document.querySelector(".amount-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".amount-error").classList.add("hidden");
    }
  };
  useEffect(() => {
    if (step === 0) {
      if (existingUser === "Yes") {
        if (selectedOptions.value) {
          setButtonEnable(false);
        }
      } else {
        if (
          !title?.label ||
          !firstName ||
          !lastName ||
          !NRCNumber ||
          !gender ||
          !dateOfBirth ||
          !email ||
          !phone ||
          !addressNumber ||
          !streetName ||
          !userAddress ||
          !bankName ||
          !accountNumber
        ) {
          setButtonEnable(true);
        } else {
          setButtonEnable(false);
        }
      }
    }
    if (step === 1) {
      if (
        !loanType?.label ||
        !address ||
        !income ||
        !employed ||
        !Public?.label ||
        !employer ||
        !employeeNumber
      ) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    }
    if (step === 2) {
      if (!amount || !duration) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    }
    if (step === 3) {
      if (
        !identityDocument ||
        !paySlip ||
        !paySlip1 ||
        !addressDocument ||
        !preApproved
      ) {
        setButtonEnable(true);
      } else {
        setButtonEnable(false);
      }
    }
  }, [
    step,
    loanType,
    address,
    income,
    employed,
    Public,
    employer,
    employeeNumber,
    amount,
    duration,
    identityDocument,
    paySlip,
    paySlip1,
    addressDocument,
    preApproved,
    existingUser,
    selectedOptions,
    title,
    firstName,
    lastName,
    NRCNumber,
    gender,
    dateOfBirth,
    email,
    phone,
    addressNumber,
    streetName,
    userAddress,
    bankName,
    accountNumber,
  ]);
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar selected={`Apply`} sidebar={sidebar} setSidebar={setSidebar} />
      <div className="apply">
        <Navbar
          section={`Apply For Loan`}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />
        <section className="apply__section">
          <div className="apply_div">
            <div className="apply__heading">
              <h2>Apply For A Loan</h2>
              <div
                id="error-container"
                style={{ display: `${showError ? "block" : "none"}` }}
              ></div>
            </div>
            <div className="apply_steps__div">
              <div className="apply__steps__name">
                <h2>Step {step + 1}</h2>
              </div>
              <div className="apply__steps__bars">
                <div className={`step-bar ${step === 0 ? "active" : ""}`}></div>
                <div className={`step-bar ${step === 1 ? "active" : ""}`}></div>
                <div className={`step-bar ${step === 2 ? "active" : ""}`}></div>
                <div className={`step-bar ${step === 3 ? "active" : ""}`}></div>
              </div>
            </div>

            <div className="apply__form">
              {step === 0 && (
                <div className="apply__form__1">
                  <div className="input-label apply__form--field existing__user__div">
                    <label className="label login__label">Existing User</label>
                    <div className="statement__form__public__service__div">
                      <div>
                        <input
                          type="radio"
                          checked={existingUser === "Yes"}
                          onClick={() => {
                            setExistingUser("Yes");
                          }}
                        />
                        <label
                          onClick={() => {
                            setExistingUser("Yes");
                          }}
                        >
                          Yes
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          checked={existingUser === "No"}
                          onClick={() => {
                            setExistingUser("No");
                          }}
                        />
                        <label
                          onClick={() => {
                            setExistingUser("No");
                          }}
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {existingUser === "Yes" && (
                    <div className="apply__form__1">
                      <div className="input-label apply__form--field receipients">
                        <label className="label login__label">
                          Select Exisitng User
                        </label>
                        {users.length >= 1 && (
                          <DropdownUsers
                            users={users}
                            singleSelect={false}
                            // type={sendMethod}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {existingUser === "No" && (
                    <div className="apply__form__1">
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Title
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="title-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Title!
                          </Popup>
                        </label>
                        <Dropdown
                          users={titleType}
                          selectedOptions={title}
                          setSelectedOptions={setTitle}
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          First Name
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="firstName-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid First Name!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={firstName}
                          onChange={(e) => {
                            validateFirstName(e);
                          }}
                          className="input login__input"
                          placeholder="John"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Last Name
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="lastName-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Last Name!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={lastName}
                          onChange={(e) => {
                            validateLastName(e);
                          }}
                          className="input login__input"
                          placeholder="Doe"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Gender
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="gender-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Gender!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={gender}
                          onChange={(e) => {
                            validateGender(e);
                          }}
                          className="input login__input"
                          placeholder="Male"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Phone Number
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="phone-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Phone Number!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => {
                            validateMobileNumber(e);
                          }}
                          className="input login__input"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          NRC Number
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="nrc-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid NRC Number!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={NRCNumber}
                          onChange={(e) => {
                            validateNRCNumber(e);
                          }}
                          className="input login__input"
                          placeholder="12345678"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Date of Birth
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="date-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Date of Birth!
                          </Popup>
                        </label>
                        <input
                          type="date"
                          value={dateOfBirth}
                          onChange={(e) => {
                            validateDateOfBirth(e);
                          }}
                          className="input login__input"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Email
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="email-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Email!
                          </Popup>
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => {
                            validateEmail(e);
                          }}
                          className="input login__input"
                          placeholder="Email"
                        />
                      </div>

                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Address Number
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="addressNumber-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Address Number!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={addressNumber}
                          onChange={(e) => {
                            validateAddressNumber(e);
                          }}
                          className="input login__input"
                          placeholder="Address Number"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Street Name
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="street-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Street!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={streetName}
                          onChange={(e) => {
                            validateStreetName(e);
                          }}
                          className="input login__input"
                          placeholder="Street Name"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Residential Area
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="residential-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Residential Area!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={address}
                          onChange={(e) => {
                            validateResidentialArea(e);
                          }}
                          className="input login__input"
                          placeholder="Residential Area"
                        />
                      </div>

                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Nationality
                        </label>
                        <input
                          type="text"
                          value="Zambia"
                          disabled
                          className="input login__input"
                          placeholder="Phone"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Bank Name
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="bank-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Bank Name!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={bankName}
                          onChange={(e) => {
                            validateBankName(e);
                          }}
                          className="input login__input"
                          placeholder="Zanaco"
                        />
                      </div>
                      <div className="apply__form--field input-label">
                        <label className="label login__label">
                          Account Number
                          <Popup
                            className="warning-popup"
                            trigger={
                              <img
                                src={Warning}
                                alt=""
                                className="account-error hidden"
                              />
                            }
                            on="hover"
                            position="top center"
                            arrow={false}
                          >
                            Enter Valid Account Number!
                          </Popup>
                        </label>
                        <input
                          type="text"
                          value={accountNumber}
                          onChange={(e) => {
                            validateAccountNumber(e);
                          }}
                          className="input login__input"
                          placeholder="1234567890"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {step === 1 && (
                <div className="apply__form__1">
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Loan Type
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="type-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Loan Type!
                      </Popup>
                    </label>
                    <Dropdown
                      users={type}
                      selectedOptions={loanType}
                      setSelectedOptions={setLoanType}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Address Duration
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="address-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Address Duration between 1 - 5!
                      </Popup>
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="1 Month"
                      max="4"
                      min="1"
                      value={address}
                      onChange={(e) => {
                        validateAddress(e);
                      }}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Income
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="income-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Income!
                      </Popup>
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000000"
                      value={income}
                      onChange={(e) => {
                        validateIncome(e);
                      }}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Employed?
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="employed-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Emplyement Status!
                      </Popup>
                    </label>
                    <input
                      type="text"
                      className="input login__input"
                      placeholder="Yes"
                      value={employed}
                      onChange={(e) => {
                        validateEmployed(e);
                      }}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">Public Service</label>
                    <Dropdown
                      users={publicService}
                      selectedOptions={Public}
                      setSelectedOptions={setPublic}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Employer
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="employer-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Employer!
                      </Popup>
                    </label>
                    <input
                      type="text"
                      className="input login__input"
                      placeholder="Vitpay"
                      value={employer}
                      onChange={(e) => {
                        validateEmployer(e);
                      }}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Employee Number
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="employee-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Employee Number!
                      </Popup>
                    </label>
                    <input
                      type="text"
                      className="input login__input"
                      placeholder="ABC123"
                      value={employeeNumber}
                      onChange={(e) => {
                        validateEmployee(e);
                      }}
                    />
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="apply__form__1">
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Loan Amount
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="amount-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Amount!
                      </Popup>
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K50000"
                      value={amount}
                      onChange={(e) => {
                        validateAmount(e);
                      }}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Loan Duration
                      <Popup
                        className="warning-popup"
                        trigger={
                          <img
                            src={Warning}
                            alt=""
                            className="duration-error hidden"
                          />
                        }
                        on="hover"
                        position="top center"
                        arrow={false}
                      >
                        Enter Valid Duration!
                      </Popup>
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="1 Month"
                      value={duration}
                      onChange={(e) => {
                        validateDuration(e);
                      }}
                    />
                  </div>

                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Insurance Fee ({interestRates.insuranceFee}%)
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={insuranceFee}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Interest Fee ({interestRates.monthlyInterest}%)
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={interest}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Processing Fee ({interestRates.processingFee}%)
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={processingFee}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Penalty Fee ({interestRates.penaltyAmount}%) (Only for
                      Late Payments)
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={0}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Monthly Installments
                    </label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={monthly}
                    />
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">Total</label>
                    <input
                      type="number"
                      className="input login__input"
                      placeholder="K1000"
                      disabled
                      value={total}
                    />
                  </div>
                </div>
              )}
              {step === 3 && (
                <div className="apply__form__1">
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Identity Document
                    </label>
                    {!identityDocument && (
                      <input
                        type="file"
                        className="input file__input"
                        id="file-upload-streaming"
                        placeholder="1 Month"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={(e) => {
                          handleUploadFileChange(e);
                        }}
                      />
                    )}
                    {!identityDocument && (
                      <label
                        htmlFor="file-upload-streaming"
                        className="file-upload-label"
                      >
                        <img src={documentImage} alt="" />
                        Click to upload Identity Document here
                      </label>
                    )}
                    {identityDocument && (
                      <div className="document__selected">
                        <h2 className="identity-document-heading identity__file">
                          {identityDocument.name}
                        </h2>
                        <img
                          src={remove}
                          alt=""
                          onClick={handleRemoveIdentity}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">Pay Slip # 1</label>
                    {!paySlip && (
                      <input
                        type="file"
                        className="input file__input"
                        id="file-upload-pay"
                        placeholder="1 Month"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={handleUploadPaySlip}
                      />
                    )}
                    {!paySlip && (
                      <label
                        htmlFor="file-upload-pay"
                        className="file-upload-label"
                      >
                        <img src={documentImage} alt="" />
                        Click to upload Pay Slip here
                      </label>
                    )}
                    {paySlip && (
                      <div className="document__selected">
                        <h2 className="identity-document-heading pay__file">
                          {paySlip.name}
                        </h2>
                        <img
                          src={remove}
                          alt=""
                          onClick={handleRemovePayslip}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">Pay Slip # 2</label>
                    {!paySlip1 && (
                      <input
                        type="file"
                        className="input file__input"
                        id="file-upload-pay1"
                        placeholder="1 Month"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={handleUploadPaySlip1}
                      />
                    )}
                    {!paySlip1 && (
                      <label
                        htmlFor="file-upload-pay1"
                        className="file-upload-label"
                      >
                        <img src={documentImage} alt="" />
                        Click to upload Pay Slip here
                      </label>
                    )}
                    {paySlip1 && (
                      <div className="document__selected">
                        <h2 className="identity-document-heading pay1__file">
                          {paySlip1.name}
                        </h2>
                        <img
                          src={remove}
                          alt=""
                          onClick={handleRemovePayslip1}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Pre Approved Letter
                    </label>
                    {!preApproved && (
                      <input
                        type="file"
                        className="input file__input"
                        id="file-upload-pre"
                        placeholder="1 Month"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={handlePreApproved}
                      />
                    )}
                    {!preApproved && (
                      <label
                        htmlFor="file-upload-pre"
                        className="file-upload-label"
                      >
                        <img src={documentImage} alt="" />
                        Click to upload Pre Approved Letter here
                      </label>
                    )}
                    {preApproved && (
                      <div className="document__selected">
                        <h2 className="identity-document-heading pre__file">
                          {preApproved.name}
                        </h2>
                        <img
                          src={remove}
                          alt=""
                          onClick={handleRemovePreApproved}
                        />
                      </div>
                    )}
                  </div>
                  <div className="input-label apply__form--field">
                    <label className="label login__label">
                      Proof of Address
                    </label>
                    {!addressDocument && (
                      <input
                        type="file"
                        className="input file__input"
                        id="file-upload-address"
                        placeholder="1 Month"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                        onChange={handleUploadAddressChange}
                      />
                    )}
                    {!addressDocument && (
                      <label
                        htmlFor="file-upload-address"
                        className="file-upload-label"
                      >
                        <img src={documentImage} alt="" />
                        Click to upload Proof of Address here
                      </label>
                    )}
                    {addressDocument && (
                      <div className="document__selected">
                        <h2 className="identity-document-heading address__file">
                          {addressDocument.name}
                        </h2>
                        <img
                          src={remove}
                          alt=""
                          onClick={handleRemoveAddress}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="apply__form__submit">
                {step !== 0 && (
                  <button className="button-back" onClick={handleBack}>
                    Back
                  </button>
                )}
                <button
                  className={`button-primary login__button ${
                    buttonEnable ? "signup_button_disabled" : ""
                  }`}
                  disabled={buttonEnable}
                  onClick={handleSubmit}
                >
                  {saveChanges ? (
                    <TailSpin
                      color={"#0C1D58"}
                      visible={saveChanges}
                      // css={override}
                      height="30"
                      width="50"
                      radius="1"
                    />
                  ) : (
                    `${step === 3 ? "Submit" : "Next"}`
                  )}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
};
