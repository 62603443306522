import "./../stylesheets/users.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Admin({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [saveChanges, setSaveChanges] = useState(false);
  const Id = useParams();
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/admin/${Id.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUser(data.user);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUser();
  }, [Id, logtail]);

  const userType = window.location.href.split("/")[3];

  const handleStatusUpdate = async (status) => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/admins/status`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            status,
            id: Id.id,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`${userType.charAt(0).toUpperCase()}${userType.slice(1)}`}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="user">
        <Navbar
          section={user ? `${user.firstName} ${user.surName}` : "User Name"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <section className="user__section">
          {user && (
            <div className="user__details">
              <div className="user__details__information">
                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Name:</h3>
                    <h4>
                      {user.firstName} {user.surName}
                    </h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Phone:</h3>
                    <h4>{user.phoneNumber}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Email:</h3>
                    <h4>{user.email}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Joined At:</h3>
                    <h4>
                      {new Date(
                        user.createdAt.split("T")[0]
                      )?.toLocaleDateString("en-GB")}
                    </h4>
                  </div>
                </div>

                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Verified:</h3>
                    <h4>{user.isVerified === true ? "Yes" : "No"}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Role:</h3>
                    <h4>{user?.permissions?.name}</h4>
                  </div>
                  {user.jobId && (
                    <div className="user__details__information_info">
                      <h3>SmileID Job ID:</h3>
                      <h4>{user.jobId}</h4>
                    </div>
                  )}
                </div>
              </div>

              {admin && admin?.admin?.permissions?.permissions?.userStatus && (
                <div className="user__balances">
                  <div className="user__actions">
                    <h3>User Actions</h3>
                    <h2>
                      <div className="wallet_buttons_actions">
                        {user.status !== "active" && (
                          <>
                            <button
                              style={{ backgroundColor: "#22a417" }}
                              onClick={() => {
                                handleStatusUpdate("active");
                              }}
                            >
                              {saveChanges ? (
                                <TailSpin
                                  color={"#0C1D58"}
                                  visible={saveChanges}
                                  // css={override}
                                  height="30"
                                  width="50"
                                  radius="1"
                                />
                              ) : (
                                "Activate"
                              )}
                            </button>
                          </>
                        )}
                        {user.status === "active" && (
                          <>
                            <button
                              style={{ backgroundColor: "red" }}
                              onClick={() => {
                                handleStatusUpdate("inactive");
                              }}
                            >
                              {saveChanges ? (
                                <TailSpin
                                  color={"#0C1D58"}
                                  visible={saveChanges}
                                  // css={override}
                                  height="30"
                                  width="50"
                                  radius="1"
                                />
                              ) : (
                                "DeActivate"
                              )}
                            </button>
                          </>
                        )}
                        <button
                          style={{ backgroundColor: "#a11414" }}
                          onClick={() => {
                            handleStatusUpdate("deleted");
                          }}
                        >
                          {saveChanges ? (
                            <TailSpin
                              color={"#0C1D58"}
                              visible={saveChanges}
                              // css={override}
                              height="30"
                              width="50"
                              radius="1"
                            />
                          ) : (
                            "Delete"
                          )}
                        </button>
                      </div>
                    </h2>
                  </div>
                </div>
              )}
              {
                <div className="user__balances">
                  <div className="wallet_permissions">
                    <h3>Admin Permissions</h3>
                    <h2>
                      <div className="wallet_buttons_permissions">
                        <div className="user__details__information_info">
                          <h3>Name:</h3>
                          <h4>{user?.permissions?.name}</h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Approve Loan:</h3>
                          <h4>
                            {user?.permissions?.permissions?.loanApprove ===
                            true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Review Loan:</h3>
                          <h4>
                            {user?.permissions?.permissions?.loanReview === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Update Interest Rates:</h3>
                          <h4>
                            {user?.permissions?.permissions?.interestRate ===
                            true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Add Admins:</h3>
                          <h4>
                            {user?.permissions?.permissions?.setUpUser === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>View Admins:</h3>
                          <h4>
                            {user?.permissions?.permissions?.adminsView === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>View Loans:</h3>
                          <h4>
                            {user?.permissions?.permissions?.loansView === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>View Users:</h3>
                          <h4>
                            {user?.permissions?.permissions?.usersView === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Repay Loans:</h3>
                          <h4>
                            {user?.permissions?.permissions?.repayment === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Update Admin/User Status:</h3>
                          <h4>
                            {user?.permissions?.permissions?.userStatus === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Email:</h3>
                          <h4>
                            {user?.permissions?.permissions
                              ?.emailSubscribers === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                        <div className="user__details__information_info">
                          <h3>Add/ Attach Roles:</h3>
                          <h4>
                            {user?.permissions?.permissions?.setUpRole === true
                              ? "Yes"
                              : "No"}
                          </h4>
                        </div>
                      </div>
                    </h2>
                  </div>
                </div>
              }
            </div>
          )}
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default Admin;
