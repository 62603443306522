// import "./../index.css";
import "./../stylesheets/users.css";

import React, { useState, useEffect, useMemo } from "react";

// import Popup from "reactjs-popup";
// import { Sidebar } from "react-pro-sidebar";
import Warning from "./../images/warning.svg";
import Navbar from "./navbar.js";

import Sidebar from "./sidebar.js";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Cross from "./../images/cross-black.svg";
import Popup from "reactjs-popup";
import { Logtail } from "@logtail/browser";
import Attachment from "./../images/attach-outline.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Loan({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [user, setUser] = useState(null);
  const [loan, setLoan] = useState(null);
  const [reasonPopup, setReasonPopup] = useState(false);
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [amount, setAmount] = useState(null);
  const [specify, setSpecify] = useState(false);
  const [extend, setExtend] = useState(false);
  const [statement, setStatement] = useState(null);
  const [statementPopup, setStatementPopup] = useState(false);
  const [requestPopup, setRequestPopup] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [type, setType] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [requestType, setRequestType] = useState("File");
  const [loading, setLoading] = useState(false);
  const Id = useParams();
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/loan/${Id.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUser(data.user);
          setLoan(data.loan);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUser();
  }, [Id, logtail]);

  const userType = "loans";

  const payInstallment = async (amountGiven) => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loan/pay`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            loanId: Id.id,
            amountGiven: amountGiven,
            amount: amount,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const addRequest = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loan/request`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            loanId: Id.id,
            remark: statement,
            type: requestType,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const extendLoan = async (period) => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loan/extend`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            loanId: Id.id,
            extensionPeriod: period,
          }),
        });
        // const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error("Image Uplaod Error");
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const approveLoan = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loan/accept`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            loanId: Id.id,
            remarks: statement,
            isAttachment: imageFile ? true : false,
            attachment: imageFile,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          // window.location.reload();
          if (data.url) {
            const img = document.querySelector(".hidden-image");
            const res = await fetch(img.src);

            const blob = await res.blob();
            const imageUpload = await fetch(data.url, {
              method: "PUT",
              body: blob,
            });
            if (imageUpload.status === 200) {
              window.location.reload();
            } else {
              toast.error("Image Uplaod Error");
            }
          } else {
            window.location.reload();
          }
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const rejectLoan = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loan/reject`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            loanId: Id.id,
            reason: statement,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  const handlePlaylistTitleInputChange = (event) => {
    setStatement(event.target.value);
  };

  const handleImageUploadChange = (event) => {
    document.querySelector(".image-error").classList.add("hidden");
    setImageFile(event.target.files[0]);
    var reader = new FileReader();
    var file = event.target.files[0];
    reader.onload = function (e) {
      var img = document.querySelector(".hidden-image");
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`${userType.charAt(0).toUpperCase()}${userType.slice(1)}`}
        sidebar={sidebar}
        setSidebar={setSidebar}
        // selected={userType}
      />
      <div className="user">
        <Navbar
          section={user ? `${user.firstName} ${user.surName}` : "Loan"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <section className="user__section">
          {loan && (
            <div className="user__details">
              <div className="user__details__information">
                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Loan Id:</h3>
                    <h4>{loan.loanId}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Loan Amount:</h3>
                    <h4>{loan.loanAmount}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Applied By:</h3>
                    <h4>
                      {loan.user.firstName} {loan.user.surName}
                    </h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Repayable Amount:</h3>
                    <h4>{loan.netLoanAmount}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Applied At:</h3>
                    <h4>
                      {new Date(
                        loan.createdAt.split("T")[0]
                      )?.toLocaleDateString("en-GB")}
                    </h4>
                  </div>

                  {loan.NRCNumber && (
                    <div className="user__details__information_info">
                      <h3>ID Number:</h3>
                      <h4>{loan.NRCNumber}</h4>
                    </div>
                  )}
                </div>

                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Status:</h3>
                    <h4>{loan.status.toUpperCase()}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Employee Number:</h3>
                    <h4>{loan.employeeNumber?.toUpperCase()}</h4>
                  </div>
                  {loan.jobId && (
                    <div className="user__details__information_info">
                      <h3>SmileID Job ID:</h3>
                      <h4>{loan.jobId}</h4>
                    </div>
                  )}
                </div>
              </div>

              <div className="user__balances">
                <div className="user__loan">
                  <h3>Loan Summary</h3>
                  <div>
                    <h2>Loan Amount: K{loan.loanAmount}</h2>
                    <h2>Repayable Amount: K{loan.netLoanAmount}</h2>
                    <h2>Paid Amount: K{loan.paidLoan?.toFixed(2)}</h2>
                    <h2>Loan Period: {loan.loanPeriod} Months</h2>
                    <h2>
                      Loan Monthly Payment: K
                      {loan.installmentDate[
                        loan.installmentIndex
                      ]?.amount?.toFixed(2)}
                    </h2>
                    <h2>
                      Loan OverDue Payment: K{loan.overdueLoan?.toFixed(2)}
                    </h2>
                    <h2>
                      Loan Repayment Date:{" "}
                      {loan.installmentDate &&
                      loan.installmentDate[
                        loan.installmentIndex
                      ]?.dueDate?.split("T")[0]
                        ? new Date(
                            loan.installmentDate[
                              loan.installmentIndex
                            ]?.dueDate?.split("T")[0]
                          )?.toLocaleDateString("en-GB")
                        : ""}
                    </h2>
                  </div>
                </div>
                <div className="user__rates">
                  <h3>Loan Logistics</h3>
                  <div>
                    <h2>Loan Type: {loan.loanType}</h2>
                    <h2>Loan Interest: K{loan.interest?.toFixed(2)}</h2>
                    <h2>Loan Insurance: K{loan.insuranceFee?.toFixed(2)}</h2>
                    <h2>
                      Loan Processing Fee: K{loan.processingFee?.toFixed(2)}
                    </h2>
                    <h2>Loan Insurance Rate: {loan.insuranceRate}%</h2>
                    <h2>Loan Interest Rate: {loan.interestRate}%</h2>
                    <h2>Loan Processing Rate: {loan.processingRate}%</h2>
                    <h2>Loan Penalty Rate: {loan.penaltyRate}%</h2>
                  </div>
                </div>
                <div className="user__details_box">
                  <h3>User Details</h3>
                  <div>
                    <h2>
                      Name: {loan.user.firstName} {loan.user.surName}
                    </h2>
                    <h2>Status: {loan.user.isVerified ? "Yes" : "No"}</h2>
                    <h2>Address Duration: {loan.addressDuration}</h2>
                    <h2>Employer: {loan.employer}</h2>
                    <h2>Employee Number: {loan.employeeNumber}</h2>
                    <h2>
                      Employment Status: {loan.employementStatus.toUpperCase()}
                    </h2>
                    <h2>Public Service: {loan.publicService}</h2>
                    <h2>Bank Name: {loan.user.bankName}</h2>
                    <h2>Bank Account Number: {loan.user.accountNumber}</h2>
                    <h2>NRC Number: {loan.user.NRCNumber}</h2>
                    <h2>
                      Date of Birth:{" "}
                      {new Date(
                        loan.user.dateOfBirth.split("T")[0]
                      )?.toLocaleDateString("en-GB")}
                    </h2>
                    <h2>Email: {loan.user.email}</h2>
                    <h2>Gender: {loan.user.gender}</h2>
                    <h2>Phone Number: {loan.user.phoneNumber}</h2>
                    <h2>
                      Address: {loan.user.residentialAddressNumber}{" "}
                      {loan.user.streetName} {loan.user.residentialArea}{" "}
                      {loan.user.nationality}
                    </h2>
                  </div>
                </div>
                {admin &&
                  (admin.admin.permissions.permissions.loanApprove ||
                    admin.admin.permissions.permissions.loanReview) && (
                    <div className="user__actions">
                      <h3>Loan Actions</h3>
                      <h2>
                        <div className="wallet_buttons_actions">
                          {loan.status === "pending" &&
                            admin.admin.permissions.permissions.loanReview && (
                              <>
                                <button
                                  style={{ backgroundColor: "#22a417" }}
                                  onClick={() => {
                                    setType("Review");
                                    console.log(type);

                                    setStatementPopup(true);
                                  }}
                                >
                                  Review
                                </button>
                              </>
                            )}
                          {loan.status === "reviewed" &&
                            admin.admin.permissions.permissions.loanApprove && (
                              <>
                                <button
                                  style={{ backgroundColor: "#22a417" }}
                                  onClick={() => {
                                    setType("Approve");
                                    setStatementPopup(true);
                                  }}
                                >
                                  Approve
                                </button>
                              </>
                            )}
                          {loan.status === "approved" &&
                            admin.admin.permissions.permissions.loanApprove && (
                              <>
                                <button
                                  style={{ backgroundColor: "#22a417" }}
                                  onClick={() => {
                                    setType("Fund");
                                    setStatementPopup(true);
                                  }}
                                >
                                  Fund
                                </button>
                              </>
                            )}
                          {(loan.status === "pending" ||
                            loan.status === "reviewed") && (
                            <>
                              <button
                                style={{ backgroundColor: "red" }}
                                onClick={() => {
                                  setReasonPopup(true);
                                }}
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      </h2>
                    </div>
                  )}
                {admin &&
                  admin.admin.permissions.permissions.repayment &&
                  (loan.status === "funded" || loan.status === "overdue") && (
                    <div className="user__actions">
                      <h3>Loan Installment</h3>
                      <h2>
                        <div className="wallet_buttons_actions">
                          {(loan.status === "funded" ||
                            loan.status === "overdue") &&
                            admin.admin.permissions.permissions.repayment && (
                              <>
                                <button
                                  style={{ backgroundColor: "#22a417" }}
                                  onClick={() => {
                                    setPaymentPopup(true);
                                  }}
                                >
                                  Pay Installment
                                </button>
                              </>
                            )}
                        </div>
                      </h2>
                    </div>
                  )}
                {admin &&
                  admin.admin.permissions.permissions.repayment &&
                  (loan.status === "funded" || loan.status === "overdue") && (
                    <div className="user__actions">
                      <h3>Loan Extension</h3>
                      <h2>
                        <div className="wallet_buttons_actions">
                          {(loan.status === "funded" ||
                            loan.status === "overdue") &&
                            admin.admin.permissions.permissions.repayment && (
                              <>
                                <button
                                  style={{ backgroundColor: "#0C1D58" }}
                                  onClick={() => {
                                    setExtend(true);
                                  }}
                                >
                                  Extend Loan Period
                                </button>
                              </>
                            )}
                        </div>
                      </h2>
                    </div>
                  )}
                {admin && admin.admin.permissions.permissions.loanApprove && (
                  <div className="user__actions">
                    <h3>Request User</h3>
                    <h2>
                      <div className="wallet_buttons_actions">
                        {admin.admin.permissions.permissions.loanApprove && (
                          <>
                            <button
                              style={{ backgroundColor: "#0064fe" }}
                              onClick={() => {
                                setRequestPopup(true);
                              }}
                            >
                              Request User
                            </button>
                          </>
                        )}
                      </div>
                    </h2>
                  </div>
                )}
              </div>
              {loan?.attachments && (
                <div className="user__documents">
                  <h3>Loan Documents</h3>
                  <div className="user__documents_links">
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.attachments.addressProof,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Proof of Address
                    </button>
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.attachments.identityDocument,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Identity Document
                    </button>
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.attachments.payslip1,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Pay Slip #1
                    </button>
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.attachments.payslip2,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Pay Slip #2
                    </button>
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.attachments.preApprovedLetter,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Pre Approved Letter
                    </button>
                    <button
                      className="user__documents_links__link"
                      onClick={() => {
                        const newTab = window.open(
                          loan.user.profilePhoto,
                          "_blank"
                        );
                        if (newTab) newTab.focus();
                      }}
                    >
                      Profile Picture
                    </button>
                  </div>
                </div>
              )}
              <div className="loan__timeline">
                <h2>Timeline</h2>
                <div className="timeline__history">
                  <div className="timeline__history__wrapper">
                    <table className="timeline__history__table">
                      <thead className="timeline__history__heading">
                        <tr className="timeline__history__heading--item">
                          <th>SR.NO</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Remarks</th>
                          <th>Attachment</th>
                        </tr>
                      </thead>
                      <tbody className="timeline__history__items">
                        {loan &&
                          loan.timeline.map((loan, index) => {
                            return (
                              <tr className="timeline__history--item">
                                <td>{index + 1}</td>
                                <td>
                                  {new Date(
                                    loan.date?.split("T")[0]
                                  )?.toLocaleDateString("en-GB")}
                                </td>
                                <td>{loan.status.toUpperCase()}</td>

                                <td>{loan.remarks ? loan.remarks : "-"}</td>

                                <td>
                                  {loan.url && (
                                    <button
                                      className="user__documents_links__link"
                                      onClick={() => {
                                        const newTab = window.open(
                                          loan.url,
                                          "_blank"
                                        );
                                        if (newTab) newTab.focus();
                                      }}
                                    >
                                      File
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="loan__timeline">
                <h2>Requests</h2>
                <div className="transactions__history">
                  <div className="transactions__history__wrapper">
                    <table className="transactions__history__table">
                      <thead className="transactions__history__heading">
                        <tr className="transactions__history__heading--item">
                          <th>SR.NO</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Request Type</th>
                          <th>Remarks</th>
                          <th>Attachment</th>
                        </tr>
                      </thead>
                      <tbody className="transactions__history__items">
                        {loan &&
                          loan.requests.map((loan, index) => {
                            return (
                              <tr className="transactions__history--item">
                                <td>{index + 1}</td>
                                <td>
                                  {new Date(
                                    loan.date?.split("T")[0]
                                  )?.toLocaleDateString("en-GB")}
                                </td>
                                <td>{loan.status.toUpperCase()}</td>
                                <td>
                                  {loan.requestType ? loan.requestType : "-"}
                                </td>

                                <td>{loan.remark ? loan.remark : "-"}</td>

                                <td>
                                  {loan.url && (
                                    <button
                                      className="user__documents_links__link"
                                      onClick={() => {
                                        const newTab = window.open(
                                          loan.url,
                                          "_blank"
                                        );
                                        if (newTab) newTab.focus();
                                      }}
                                    >
                                      File
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <Popup
        className="create-playlist-popup"
        modal
        open={reasonPopup}
        onOpen={() => {
          setReasonPopup(true);
        }}
        onClose={() => {
          setReasonPopup(false);
        }}
        nested
      >
        <div className="create-playlist-popup-heading">
          <h3>Reason for Rejection</h3>
          <img
            src={Cross}
            alt=""
            onClick={() => {
              setReasonPopup(false);
            }}
          />
        </div>
        <div className="create-playlist-popup-container">
          <p>
            Reason
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="title-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Add Remark
            </Popup>
          </p>
          <input
            type="text"
            onChange={handlePlaylistTitleInputChange}
            value={statement}
          />

          <button
            className={`${"create-playlist-button"} ${
              saveChanges ? "button__disabled" : ""
            }`}
            onClick={rejectLoan}
            disabled={saveChanges}
          >
            {saveChanges ? (
              <TailSpin
                color={"#0C1D58"}
                visible={saveChanges}
                // css={override}
                height="30"
                width="50"
                radius="1"
              />
            ) : (
              "Reject"
            )}
          </button>
        </div>
      </Popup>
      <Popup
        open={extend}
        onOpen={() => {
          setExtend(true);
        }}
        onClose={() => {
          setExtend(false);
        }}
        className="amount-popup"
      >
        <h3>
          Extend Loan
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="#000000"
            viewBox="0 0 256 256"
            onClick={() => {
              setExtend(false);
            }}
          >
            <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
          </svg>
        </h3>
        <div className="extend__loan">
          <h2>Extend the Loan Period</h2>
          <div>
            <button
              style={{ backgroundColor: "#0C1D58" }}
              onClick={() => {
                extendLoan(1);
              }}
            >
              1 Month
            </button>
            <button
              style={{ backgroundColor: "#0064fe" }}
              onClick={() => {
                extendLoan(2);
              }}
            >
              2 Months
            </button>
          </div>
        </div>
      </Popup>
      <Popup
        className="create-playlist-popup"
        modal
        open={statementPopup}
        onOpen={() => {
          setStatementPopup(true);
        }}
        onClose={() => {
          setStatementPopup(false);
        }}
        nested
      >
        <div className="create-playlist-popup-heading">
          <h3>Add Remarks</h3>
          <img
            src={Cross}
            alt=""
            onClick={() => {
              setStatementPopup(false);
            }}
          />
        </div>
        <div className="create-playlist-popup-container">
          <p>
            Remarks
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="title-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Add Remark
            </Popup>
          </p>
          <input
            type="text"
            onChange={handlePlaylistTitleInputChange}
            value={statement}
          />
          <p>
            Add Attachment
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="image-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Upload a valid image file!
            </Popup>
          </p>

          <label
            className="create-playlist-popup__upload-image"
            htmlFor="playlist-image-upload"
          >
            <img src={Attachment} alt="" />
            <img className="hidden-image" alt=""></img>
            {!imageFile && "Attach Image"}
            {imageFile &&
              (imageFile.name.length <= 11
                ? imageFile.name
                : `${imageFile.name.slice(0, 10)}....`)}
          </label>

          <input
            type="file"
            id="playlist-image-upload"
            accept="image/png, image/jpeg, image/gif, image/svg"
            onChange={handleImageUploadChange}
          />

          <button
            className={`${"create-playlist-button"} ${
              saveChanges ? "button__disabled" : ""
            }`}
            onClick={approveLoan}
            disabled={saveChanges}
          >
            {saveChanges ? (
              <TailSpin
                color={"#0C1D58"}
                visible={saveChanges}
                // css={override}
                height="30"
                width="50"
                radius="1"
              />
            ) : (
              `${type ? type : "Wait"}`
            )}
          </button>
        </div>
      </Popup>
      <Popup
        className="create-playlist-popup"
        modal
        open={requestPopup}
        onOpen={() => {
          setRequestPopup(true);
        }}
        onClose={() => {
          setRequestPopup(false);
        }}
        nested
      >
        <div className="create-playlist-popup-heading">
          <h3>Request User</h3>
          <img
            src={Cross}
            alt=""
            onClick={() => {
              setRequestPopup(false);
            }}
          />
        </div>
        <div className="create-playlist-popup-container">
          <p>
            Remarks
            <Popup
              className="upload-error-popup"
              trigger={
                <img src={Warning} alt="" className="title-error hidden"></img>
              }
              on="hover"
              position="top center"
              arrow={false}
            >
              Add Remark
            </Popup>
          </p>
          <input
            type="text"
            onChange={handlePlaylistTitleInputChange}
            value={statement}
          />
          <div>
            <div className="statement__form__public__service__div">
              <div>
                <input
                  type="radio"
                  checked={requestType === "File"}
                  onClick={() => {
                    setRequestType("File");
                  }}
                />
                <label
                  onClick={() => {
                    setRequestType("File");
                  }}
                >
                  File
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  checked={requestType === "Visit"}
                  onClick={() => {
                    setRequestType("Visit");
                  }}
                />
                <label
                  onClick={() => {
                    setRequestType("Visit");
                  }}
                >
                  Visit
                </label>
              </div>
            </div>
          </div>
          <button
            className={`${"create-playlist-button"} ${
              saveChanges ? "button__disabled" : ""
            }`}
            onClick={addRequest}
            disabled={saveChanges}
          >
            {saveChanges ? (
              <TailSpin
                color={"#0C1D58"}
                visible={saveChanges}
                // css={override}
                height="30"
                width="50"
                radius="1"
              />
            ) : (
              "Add Request"
            )}
          </button>
        </div>
      </Popup>
      <Popup
        className="create-playlist-popup wallet-popup"
        modal
        open={paymentPopup}
        onOpen={() => {
          setPaymentPopup(true);
        }}
        onClose={() => {
          setPaymentPopup(false);
        }}
        nested
        // className="create-playlist-popup wallet-popup"
        // trigger={
        //   <button className="wallet-balance-withdraw-button">Deposit</button>
        // }
        // modal
        // onClose={() => {
        //   // setSelectedDepositMethod(null);
        // }}
      >
        <>
          <div className="create-playlist-popup-heading">
            <h3>Pay Installment Method</h3>
            <img
              className="counter-offer-popup__close"
              src={Cross}
              alt=""
              onClick={() => {
                setPaymentPopup(false);
              }}
            />
          </div>

          <div className="wallet-popup-container">
            <button
              style={{ border: "1px solid #0C1D58" }}
              onClick={() => {
                payInstallment(false);
              }}
            >
              {saveChanges && (
                <TailSpin
                  color={"#0C1D58"}
                  visible={saveChanges}
                  // css={override}
                  height="30"
                  width="50"
                  radius="1"
                />
              )}
              {!saveChanges && "Preset Amount"}
              {/* <img src={paypal} alt="" /> */}
            </button>

            <button
              style={{ border: "1px solid #FE2B0D" }}
              onClick={() => {
                setSpecify(true);
              }}
            >
              Specify Amount
            </button>
            {specify && (
              <>
                <label>
                  Installment Amount
                  <Popup
                    className="upload-error-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="deposit-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    {/* {serverDepositErrorMessage && (
                        <>{serverDepositErrorMessage}</>
                      )}
                      {!serverDepositErrorMessage &&
                      selectedDepositMethod === "Paystack" &&
                      depositInput.slice(1) < 50
                        ? "Minimum deposit amount is K50"
                        : depositInput.length < 2
                        ? "Deposit amount cannot be empty!"
                        : null} */}
                  </Popup>
                </label>
                <input
                  type="text"
                  placeholder={`K20`}
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <div className="button-container">
                  <button
                    disabled={saveChanges}
                    className="button-container__submit deposit__button"
                    onClick={() => {
                      payInstallment(true);
                    }}
                  >
                    {saveChanges && (
                      <TailSpin
                        color={"#0C1D58"}
                        visible={saveChanges}
                        // css={override}
                        height="30"
                        width="50"
                        radius="1"
                      />
                    )}
                    {!saveChanges && "Pay"}
                  </button>
                </div>
              </>
            )}
          </div>
        </>
      </Popup>
      <ToastContainer />
    </>
  );
}

export default Loan;
