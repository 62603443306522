import "./../src/App.css";
import { Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "../src/components/login.js";
import VerifyAccount from "./components/verify.js";
import Dashboard from "../src/components/dashboard";
import Transactions from "../src/components/loans";
import Users from "../src/components/users";
import User from "../src/components/user";
import Admins from "../src/components/admins";
import Loan from "../src/components/loan";
import Admin from "./components/admin.js";
import Roles from "./components/roles.js";
import CreateRole from "./components/createRole.js";
import Email from "./components/email.js";
import Fees from "../src/components/interest.js";
import Settings from "../src/components/settings";
import ChangePassword from "../src/components/changePassword";
import ForgetPassword from "./components/forgetPassword.js";
import ResetPassword from "./components/resetPassword.js";
import AddAccount from "./components/addAccount.js";
import Statement from "./components/statement.js";
import Analytics from "./components/analytics.js";
import { Apply } from "./components/applyForLoan.js";
const App = () => {
  const [user, setUser] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  useEffect(() => {
    const checkUser = async (data) => {
      const token = data ? data.token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/user`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        });
        if (response.status === 403) {
          // localStorage.removeItem("vitpay_data");
          return false;
        }
        const data = await response.json();
        if (data.user) {
          return true;
        } else {
          // localStorage.removeItem("vitpay_data");
          window.location.href = "/login";

          return false;
        }
      }
    };
    const data = JSON.parse(localStorage.getItem("vitpay_data"));
    if (data) {
      const check = checkUser(data);
      if (check) {
        setUser(data);
      } else {
        setUser(null);
        window.location.href = "/login";
      }
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("vitpay_data")) {
      const access_token = JSON.parse(localStorage.getItem("vitpay_data"));
      const token = access_token.token;
      const expirationTime = access_token.expiresIn;

      if (token && expirationTime) {
        const currentTime = Date.now();
        if (currentTime > expirationTime) {
          localStorage.removeItem("vitpay_data");
          window.dispatchEvent(new Event("storage"));
          setUser(null);
          window.location.href = "/login";
        } else {
          const timeUntilExpiration = expirationTime - currentTime;
          const logoutTimer = setTimeout(() => {
            localStorage.removeItem("vitpay_data");
            window.dispatchEvent(new Event("storage"));
            setUser(null);
            window.location.href = "/login";
          }, timeUntilExpiration);

          return () => clearTimeout(logoutTimer);
        }
      }
    }
  }, [user]);
  return (
    <Router>
      <div className="main__div">
        <Routes>
          <Route
            path="/login"
            element={
              <Login
                admin={user}
                setUser={setUser}
                sidebar={sidebar}
                setSidebar={setSidebar}
              />
            }
          />
          <Route
            path="/verify"
            element={
              <VerifyAccount
                admin={user}
                setUser={setUser}
                sidebar={sidebar}
                setSidebar={setSidebar}
              />
            }
          />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          {user && (
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/users"
              element={
                <Users
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/loans/:type"
              element={
                <Transactions
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/statement"
              element={
                <Statement
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/users/:id"
              element={
                <User
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/loan/:id"
              element={
                <Loan
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/admin/:id"
              element={
                <Admin
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/fees"
              element={
                <Fees
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/analytics"
              element={
                <Analytics
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/roles"
              element={
                <Roles
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/apply"
              element={
                <Apply
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/roles/create"
              element={
                <CreateRole
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/email"
              element={
                <Email
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}

          {user && (
            <Route
              path="/settings/signup"
              element={
                <AddAccount
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/settings/changePassword"
              element={
                <ChangePassword
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/settings"
              element={
                <Settings
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                />
              }
            />
          )}
          {user && (
            <Route
              path="/admins"
              element={
                <Admins
                  admin={user}
                  setUser={setUser}
                  sidebar={sidebar}
                  setSidebar={setSidebar}
                  type={"agents"}
                />
              }
            />
          )}
          <Route
            path="*"
            element={
              <Login
                admin={user}
                setUser={setUser}
                sidebar={sidebar}
                setSidebar={setSidebar}
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
