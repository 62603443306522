import "./../stylesheets/users.css";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Admins({ admin, sidebar, setSidebar }) {
  const [users, setUsers] = useState(null);
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState(null);

  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [loading, setLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/admins`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUsers(data.users);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAllUsers();
    const getAllRoles = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/roles`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setRoles(data.roles);
          setSelectedRole(data.roles[0].name);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAllRoles();
  }, [logtail]);
  useEffect(() => {
    if (data) {
      if (data.users) {
        setUsers(data.users);
      }
    }
  }, [data]);
  const navigate = useNavigate();

  const userButton = function (event) {
    navigate(`/admin/${event.target.dataset.id}`);
  };
  useEffect(() => {
    if (data) {
      if (data.users) {
        setUsers(data.users);
      }
    }
  }, [data]);

  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const statusOptions = ["Active", "Inactive", "Deleted"];
  const [selectedStatusOption, setSelectedStatusOption] = useState(
    statusOptions[0]
  );
  const [selectedRole, setSelectedRole] = useState(roles ? roles[0].name : "");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [popupStates, setPopupStates] = useState({
    statusPopup: false,
    rolePopup: false,
    // typePopup: false,
  });

  const handlePopupOpen = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const handlePopupClose = (popupName) => {
    setPopupStates((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };

  const handleFilter = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/admins/filter`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            status: [`${selectedStatusOption.toLowerCase()}`],
            startDate: startDate,
            endDate: endDate,
            role: selectedRole,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          if (data.users) {
            setUsers(data.users);
            setSaveChanges(false);
          }
          setSaveChanges(false);
        }
        setSaveChanges(false);
      }
    } catch (error) {
      setSaveChanges(false);
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar selected={"Admins"} sidebar={sidebar} setSidebar={setSidebar} />
      <div className="users">
        <Navbar
          section="Admins"
          setData={setData}
          searchType={`admins`}
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <section className="users__section">
          <div className="loans__dashboard__buttons">
            <div className="transactions__filter">
              <button
                style={
                  isFilterExpanded
                    ? { backgroundColor: "#516BD5" }
                    : { backgroundColor: "#516BD53D", color: "#516BD5" }
                }
                onClick={() => {
                  setIsFilterExpanded(!isFilterExpanded);
                }}
              >
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Filter"
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 256 256"
                  fill={isFilterExpanded ? "white" : "#516BD5"}
                >
                  <path d="M230.6,49.53A15.81,15.81,0,0,0,216,40H40A16,16,0,0,0,28.19,66.76l.08.09L96,139.17V216a16,16,0,0,0,24.87,13.32l32-21.34A16,16,0,0,0,160,194.66V139.17l67.74-72.32.08-.09A15.8,15.8,0,0,0,230.6,49.53ZM40,56h0Zm108.34,72.28A15.92,15.92,0,0,0,144,139.17v55.49L112,216V139.17a15.92,15.92,0,0,0-4.32-10.94L40,56H216Z"></path>
                </svg>
              </button>
            </div>
          </div>
          {isFilterExpanded && (
            <div className="filter">
              <div className="filter_row">
                <div className="filter_container">
                  <h5>Join Date:</h5>
                  <input
                    type="date"
                    onChange={(event) => {
                      setStartDate(event.target.value);
                    }}
                    placeholder="From"
                  />
                  <input
                    type="date"
                    onChange={(event) => {
                      setEndDate(event.target.value);
                    }}
                    placeholder="To"
                  />
                </div>
                <div className="filter_container">
                  <h5>Status:</h5>
                  <Popup
                    className="filter_popup"
                    trigger={
                      <div>
                        {selectedStatusOption}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#000000"
                          viewBox="0 0 256 256"
                          style={
                            popupStates.statusPopup
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                        >
                          <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                        </svg>
                      </div>
                    }
                    onOpen={() => {
                      handlePopupOpen("timePopup");
                    }}
                    onClose={() => {
                      handlePopupClose("timePopup");
                    }}
                    open={popupStates.timePopup}
                    arrow={false}
                    position="bottom center"
                  >
                    {(close) => (
                      <>
                        {statusOptions.map((option) => (
                          <button
                            style={
                              option === selectedStatusOption
                                ? { backgroundColor: "#ddd" }
                                : null
                            }
                            onClick={() => {
                              setSelectedStatusOption(option);
                              close();
                            }}
                          >
                            {option}
                          </button>
                        ))}
                      </>
                    )}
                  </Popup>
                </div>
                <div className="filter_role_container">
                  <h5>Role:</h5>
                  <Popup
                    className="filter_role_popup"
                    trigger={
                      <div>
                        {selectedRole}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#000000"
                          viewBox="0 0 256 256"
                          style={
                            popupStates.rolePopup
                              ? { transform: "rotate(180deg)" }
                              : { transform: "none" }
                          }
                        >
                          <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                        </svg>
                      </div>
                    }
                    onOpen={() => {
                      handlePopupOpen("rolePopup");
                    }}
                    onClose={() => {
                      handlePopupClose("rolePopup");
                    }}
                    open={popupStates.rolePopup}
                    arrow={false}
                    position="bottom center"
                  >
                    {(close) => (
                      <>
                        {roles.map((option) => (
                          <button
                            style={
                              option === selectedStatusOption
                                ? { backgroundColor: "#ddd" }
                                : null
                            }
                            onClick={() => {
                              setSelectedRole(option.name);
                              close();
                            }}
                          >
                            {option.name}
                          </button>
                        ))}
                      </>
                    )}
                  </Popup>
                </div>
              </div>
              <div className="filter_row">
                <button onClick={handleFilter}>Apply Filter</button>
              </div>
            </div>
          )}
          <div className="users__history">
            <div className="users__history__wrapper">
              <table className="users__history__table">
                <thead className="users__history__heading">
                  <tr className="users__history__heading--item">
                    <th>Sr.No</th>
                    <th class="user_email">Email</th>
                    <th>Username</th>
                    <th>Phone Number</th>
                    <th>Date</th>
                    <th>Role</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="users__history__items">
                  {users &&
                    users.map((user, index) => {
                      return (
                        <tr className="users__history--item" key={index}>
                          <td>{index + 1}</td>

                          <td className="user_email">{user.email}</td>
                          <td>{user.userName}</td>
                          <td>{user.phoneNumber}</td>
                          <td>
                            {new Date(
                              user.createdAt?.split("T")[0]
                            )?.toLocaleDateString("en-GB")}
                          </td>
                          <td>{user?.permissions?.name}</td>
                          <td>
                            {admin &&
                              admin.admin.permissions.permissions
                                .adminsView && (
                                <button onClick={userButton} data-id={user._id}>
                                  View
                                </button>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default Admins;
