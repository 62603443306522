import "./../stylesheets/login.css";

import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "./../images/Logo.svg";
import Eye_On from "./../images/eye-outline.svg";
import Eye_Off from "./../images/eye-off-outline.svg";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import "./../stylesheets/toast.css";
import { Logtail } from "@logtail/browser";

function Login({ user, setUser }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);

  const [buttonEnable, setButtonEnable] = useState(false);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (user) {
      const tokenData = localStorage.getItem("vitpay_data");
      const access_token = JSON.parse(tokenData);
      const token = access_token.token;
      if (token) {
        navigate("/dashboard");
      } else {
        setUser(null);
      }
    }
  }, [user, navigate, setUser]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      setButtonEnable(false);
      setLoading(true);
      setShowError(false);
      const url =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_FRONTEND_URL
          : process.env.REACT_APP_LOCAL_URL;
      const response = await fetch(`${url}/admin/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userName: userName, password }),
      });
      const data = await response.json();
      if (response.status !== 200) {
        toast.error(data.message);
        setLoading(false);
        setButtonEnable(false);
      } else {
        if (data.admin.loginAttempt === 1) {
          navigate(
            `/verify?email=${data.admin.email}&userName=${data.admin.userName}`
          );
        } else {
          localStorage.setItem("vitpay_data", JSON.stringify(data));
          setUser(data);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      logtail.error("Error in login", error);
      logtail.flush();
      toast.error(error.message);
    }
  };
  return (
    <section className="login__section">
      <div className="login__box">
        <div className="login__box__image">
          <img src={Logo} alt="logo"></img>
        </div>
        <div className="login__box__form">
          <div className="login__box__form__details">
            <h2>Welcome Back</h2>
            <p>Enter your Login credentials to enter into your account</p>
          </div>
          <div
            id="error-container"
            style={{ display: `${showError ? "block" : "none"}` }}
          ></div>
          <div className="login__box__form__fields">
            <div className="login__box__form__fields__email">
              <input
                type="email"
                placeholder="johnDoe"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              ></input>
            </div>
            <div className="login__box__form__fields__password">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <button
                className="show_password"
                onClick={togglePasswordVisibility}
              >
                <img
                  src={showPassword ? Eye_Off : Eye_On}
                  alt="show_password"
                ></img>
              </button>
            </div>
          </div>
          <div className="login__box__form__submit">
            <button
              className={`${"signup__submit"} ${
                loading ? "button__disabled" : ""
              }${buttonEnable ? "signup_button_disabled" : ""}`}
              onClick={handleLoginSubmit}
              disabled={buttonEnable}
            >
              {loading ? (
                <TailSpin
                  color={"#0C1D58"}
                  visible={loading}
                  // css={override}
                  height="30"
                  width="50"
                  radius="1"
                />
              ) : (
                "Login"
              )}
            </button>
          </div>
          <div className="login__box__form__forget_password">
            <Link to={`/forgetpassword`}>
              <span>Forgot Password?</span>
            </Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default Login;
