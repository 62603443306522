import React from "react";
import Select from "react-select";

function RolesDropdown(props) {
  const { users, setSelectedOptions, selectedOptions } = props;
  // Example array of users
  // Format users array for React Select options
  const options = [
    ...users.map((user) => ({
      value: user._id,
      label: `${user.name}`,
    })),
  ];

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  return (
    <div className="roles__dropdown__div">
      <p className="signup__box__form__fields__email">Attach a Role</p>
      <Select
        className="roles__dropdown"
        classNamePrefix="roles__dropdown"
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        placeholder="Select..."
      />
      {/* {selectedOptions.length > 0 && (
        <div className="selected-users">
          {selectedOptions.map((option) => (
            <p key={option.value}>{option.label}</p>
          ))}
        </div>
      )} */}
    </div>
  );
}

export default RolesDropdown;
