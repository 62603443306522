// import "./../index.css";
import "./../stylesheets/users.css";

import React, { useState, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useNavigate } from "react-router-dom";

import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function CreateRole({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({
    loanApprove: false,
    loanAdministrator: false,
    interestRate: false,
    setUpUser: false,
    admins: false,
    loans: false,
    userStatus: false,
    emailSubscribers: false,
    setUpRoles: false,
  });
  const [saveChanges, setSaveChanges] = useState(false);
  const handleSubmit = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      //
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/role`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            roleName: roleName,
            permissions: permissions,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          navigate("/roles");
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
    }
  };
  return (
    <>
      <Sidebar selected={`Roles`} sidebar={sidebar} setSidebar={setSidebar} />
      <div className="transactions">
        <Navbar section={`Roles`} sidebar={sidebar} setSidebar={setSidebar} />
        <section className="users__section">
          <div className="roles_form">
            <div className="roles_form__div_name">
              <label>Role Name</label>
              <input
                type="text"
                placeholder="Name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.loanApprove}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    loanApprove: e.target.checked,
                  })
                }
              />
              <label>Approve Loans</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.loanAdministrator}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    loanAdministrator: e.target.checked,
                  })
                }
              />
              <label>Loan Administrator</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.interestRate}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    interestRate: e.target.checked,
                  })
                }
              />
              <label>Set Interest Rates</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.setUpUser}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    setUpUser: e.target.checked,
                  })
                }
              />
              <label>Set Up Admins</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.admins}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    admins: e.target.checked,
                  })
                }
              />
              <label>View Admins</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.loans}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    loans: e.target.checked,
                  })
                }
              />
              <label>View Loans</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.userStatus}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    userStatus: e.target.checked,
                  })
                }
              />
              <label>Update User Status</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.emailSubscribers}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    emailSubscribers: e.target.checked,
                  })
                }
              />
              <label>Email Subscribers</label>
            </div>
            <div className="roles_form__div">
              <input
                type="checkbox"
                checked={permissions.setUpRoles}
                onChange={(e) =>
                  setPermissions({
                    ...permissions,
                    setUpRoles: e.target.checked,
                  })
                }
              />
              <label>Set Up Roles</label>
            </div>
            {admin && admin?.admin?.permissions?.permissions?.setUpRole && (
              <button className="add_role__button" onClick={handleSubmit}>
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Create A Role"
                )}
              </button>
            )}
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}
export default CreateRole;
