import "./../stylesheets/users.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function User({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);
  const [loans, setLoans] = useState(null);
  const [loading, setLoading] = useState(false);
  const Id = useParams();
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/users/${Id.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUser(data.user);
          setData(data);
          setLoans(data.user.loans);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUser();
  }, [Id, logtail]);

  const userType = window.location.href.split("/")[3];
  const [saveChanges, setSaveChanges] = useState(false);
  const handleStatusUpdate = async (status) => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/users/status`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            status,
            id: Id.id,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          window.location.reload();
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`${userType.charAt(0).toUpperCase()}${userType.slice(1)}`}
        sidebar={sidebar}
        setSidebar={setSidebar}
        // selected={userType}
      />
      <div className="user">
        <Navbar
          section={user ? `${user.firstName} ${user.surName}` : "User Name"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <section className="user__section">
          {user && (
            <div className="user__details">
              <div className="user__details__information">
                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Name:</h3>
                    <h4>
                      {user.firstName} {user.surName}
                    </h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Phone:</h3>
                    <h4>{user.phoneNumber}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Country:</h3>
                    <h4>{user.nationality}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Joined At:</h3>
                    <h4>
                      {new Date(
                        user.createdAt.split("T")[0]
                      )?.toLocaleDateString("en-GB")}
                    </h4>
                  </div>

                  {user.NRCNumber && (
                    <div className="user__details__information_info">
                      <h3>ID Number:</h3>
                      <h4>{user.NRCNumber}</h4>
                    </div>
                  )}
                </div>

                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Verified:</h3>
                    <h4>{user.isVerified === true ? "Yes" : "No"}</h4>
                  </div>
                  <div className="user__details__information_info">
                    <h3>Employee Number:</h3>
                    <h4>{user.employeeNumber?.toUpperCase()}</h4>
                  </div>
                  {user.jobId && (
                    <div className="user__details__information_info">
                      <h3>SmileID Job ID:</h3>
                      <h4>{user.jobId}</h4>
                    </div>
                  )}
                </div>
                <div className="user__details__information_column">
                  <div className="user__details__information_info">
                    <h3>Profile Photo:</h3>

                    <img src={user.profilePhoto} alt="profile"></img>
                  </div>
                </div>
              </div>

              <div className="user__balances">
                <div className="user__balance">
                  <h3>Loan Summary</h3>
                  <h2>Number of Loans Applied: K{data.totalLoansTaken}</h2>
                  <h2>Amount of Loans Applied: K{data.totalAmountTaken}</h2>
                  <h2>Amount to be Returned: K{data.totalAmountYetToReturn}</h2>
                  <h2>Amount Paid Back: K{data.totalAmountPaidBack}</h2>
                </div>

                {admin && admin.admin.permissions.permissions.userStatus && (
                  <div className="user__actions">
                    <h3>User Actions</h3>
                    <h2>
                      <div className="wallet_buttons_actions">
                        {user.status !== "active" && (
                          <>
                            <button
                              style={{ backgroundColor: "#22a417" }}
                              onClick={() => {
                                handleStatusUpdate("active");
                              }}
                            >
                              {saveChanges ? (
                                <TailSpin
                                  color={"#0C1D58"}
                                  visible={saveChanges}
                                  // css={override}
                                  height="30"
                                  width="50"
                                  radius="1"
                                />
                              ) : (
                                "Activate"
                              )}
                            </button>
                          </>
                        )}
                        {user.status === "active" && (
                          <>
                            <button
                              style={{ backgroundColor: "red" }}
                              onClick={() => {
                                handleStatusUpdate("inactive");
                              }}
                            >
                              {saveChanges ? (
                                <TailSpin
                                  color={"#0C1D58"}
                                  visible={saveChanges}
                                  // css={override}
                                  height="30"
                                  width="50"
                                  radius="1"
                                />
                              ) : (
                                "DeActivate"
                              )}
                            </button>
                          </>
                        )}
                        <button
                          style={{ backgroundColor: "#a11414" }}
                          onClick={() => {
                            handleStatusUpdate("deleted");
                          }}
                        >
                          {saveChanges ? (
                            <TailSpin
                              color={"#0C1D58"}
                              visible={saveChanges}
                              // css={override}
                              height="30"
                              width="50"
                              radius="1"
                            />
                          ) : (
                            "Delete"
                          )}
                        </button>
                      </div>
                    </h2>
                  </div>
                )}
              </div>

              <div className="user__transaction__history">
                <h3>Loan History</h3>
                <div className="transactions__history">
                  <div className="transactions__history__wrapper">
                    <table className="transactions__history__table">
                      <thead className="transactions__history__heading">
                        <tr className="transactions__history__heading--item">
                          <th>Sr.No</th>
                          <th>Date</th>
                          <th>Applied By</th>
                          <th>Additional Loan</th>
                          <th>Amount</th>
                          <th>Loan Type</th>
                          <th>Loan Period</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="transactions__history__items">
                        {loans &&
                          loans.map((loan, index) => {
                            return (
                              <tr className="transactions__history--item">
                                <td>{index + 1}</td>
                                <td>
                                  {new Date(
                                    loan.createdAt.split("T")[0]
                                  )?.toLocaleDateString("en-GB")}
                                </td>
                                <td>
                                  {user.firstName} {user.surName}
                                </td>

                                <td>{loan.isAdditionalLoan ? "Yes" : "No"} </td>
                                <td
                                  className={`${
                                    loan.loanAmount > 0
                                      ? "transaction_deposit"
                                      : "transaction_withdrawal"
                                  }`}
                                >
                                  {loan.loanAmount < 0
                                    ? loan.loanAmount * -1
                                    : loan.loanAmount}
                                </td>
                                <td>{loan.loanType}</td>
                                <td>{loan.loanPeriod} Months</td>

                                <td
                                  className={`${
                                    loan.status === "approved"
                                      ? "transaction_success"
                                      : loan.status === "pending"
                                      ? "transaction_pending"
                                      : "transaction_failure"
                                  }`}
                                >
                                  {loan.status === "approved"
                                    ? "Approved"
                                    : loan.status === "pending"
                                    ? "Pending"
                                    : "Rejected"}
                                </td>
                                <td>
                                  {admin &&
                                    admin.admin.permissions.permissions
                                      .loansView && (
                                      <button
                                        // onClick={userButton}
                                        data-id={loan._id}
                                      >
                                        View
                                      </button>
                                    )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default User;
