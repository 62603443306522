import "./../stylesheets/login.css";
import React, { useState, useMemo } from "react";
import Logo from "./../images/logo.png";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import Warning from "./../images/warning.svg";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ForgetPassword({ user, setUser }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(true);
  const [saveChanges, setSaveChanges] = useState(false);
  const validateEmail = (event) => {
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(event.target.value)) {
      document.querySelector(".email-error").classList.remove("hidden");
      setButtonEnable(true);

      //   document.querySelector(".submit-button").disabled = true;
    } else {
      document.querySelector(".email-error").classList.add("hidden");
      setButtonEnable(false);
    }
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setButtonEnable(true);
    try {
      const url =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_FRONTEND_URL
          : process.env.REACT_APP_LOCAL_URL;
      const response = await fetch(`${url}/admin/user/password/forget`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      const data = await response.json();
      if (response.status === 200) {
        setEmailSent(true);
        setSaveChanges(false);
      } else {
        toast.error(data.message);
        setSaveChanges(false);
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
    }
  };
  const handleResetPassword = () => {
    navigate(`/resetPassword?email=${email}`);
  };
  return (
    <>
      <section className="login__section">
        {!emailSent && (
          <div className="login__box">
            <div className="login__box__image">
              <img src={Logo} alt="logo"></img>
            </div>
            <div className="login__box__form">
              <div className="login__box__form__details">
                <h2>Forget Password</h2>
                <p>Enter your Email</p>
              </div>
              <div className="signup__box__form__fields">
                <div>
                  <p className="signup__box__form__fields__email">
                    Email Address
                    <Popup
                      className="warning-popup"
                      trigger={
                        <img
                          src={Warning}
                          alt=""
                          className="email-error hidden"
                        />
                      }
                      on="hover"
                      position="top center"
                      arrow={false}
                    >
                      Enter Valid Email!
                    </Popup>
                  </p>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={validateEmail}
                  ></input>
                </div>
              </div>
              <div className="login__box__form__submit">
                <button
                  onClick={handleLoginSubmit}
                  disable={buttonEnable}
                  className={`signup__submit ${
                    buttonEnable ? "signup_button_disabled" : ""
                  }`}
                >
                  {saveChanges ? (
                    <TailSpin
                      color={"#0C1D58"}
                      visible={saveChanges}
                      // css={override}
                      height="30"
                      width="50"
                      radius="1"
                    />
                  ) : (
                    "Send Email"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        {emailSent && (
          <div className="login__box">
            <div className="login__box__image">
              <img src={Logo} alt="logo"></img>
            </div>
            <div className="login__box__form">
              <div className="login__box__form__details">
                <h2>
                  An Email with the otp has been to your Email Address: {email}
                </h2>
              </div>
              <div className="login__box__form__submit continue__button">
                <button onClick={handleResetPassword}>
                  Continue to reset your password
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      <ToastContainer />
    </>
  );
}

export default ForgetPassword;
