import "./../stylesheets/login.css";
import "./../stylesheets/signup.css";
import "./../stylesheets/users.css";
import React, { useState, useEffect, useMemo } from "react";
import Logo from "./../images/logo.png";
import Eye_On from "./../images/eye-outline.svg";
import Eye_Off from "./../images/eye-off-outline.svg";
import Popup from "reactjs-popup";
import Warning from "./../images/warning.svg";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function VerifyAccount({ user, setUser }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [buttonEnable, setButtonEnable] = useState(true);
  const [showError, setShowError] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  useEffect(() => {
    if (user) {
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        navigate("/dashboard");
      } else {
        setUser(null);
      }
    }
  }, [user, navigate, setUser]);

  useEffect(() => {
    const isValidOTP = (otp) => {
      const mobileRegex = /^[0-9]{4}$/;
      return mobileRegex.test(otp);
    };
    const isValidPassword = (password) => {
      const minLength = 8;
      const maxLength = 128;
      return password.length >= minLength && password.length <= maxLength;
    };
    const isValidConfirmPassword = (confirmPassword) => {
      return confirmPassword === password;
    };
    if (
      isValidOTP(otp) &&
      isValidPassword(password) &&
      isValidConfirmPassword(confirmPassword)
    ) {
      setButtonEnable(false);
    }
  }, [otp, password, confirmPassword]);
  function otpValid(event) {
    setOtp(event.target.value);
    const mobileRegex = /^[0-9]{4}$/;
    if (!mobileRegex.test(event.target.value)) {
      document.querySelector(".otp-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".otp-error").classList.add("hidden");
    }
  }
  function validatePassword(event) {
    setPassword(event.target.value);
    const minLength = 8;
    const maxLength = 128;

    if (event.target.value.length < minLength) {
      document.querySelector(".password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else if (event.target.value.length > maxLength) {
      document.querySelector(".password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".password-error").classList.add("hidden");
    }
  }
  const handleRegenerateOTP = async () => {
    const params = new URLSearchParams(window.location.search);
    const username = params.get("userName");
    const url =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_FRONTEND_URL
        : process.env.REACT_APP_LOCAL_URL;
    const response = await fetch(`${url}/admin/otp/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userName: username }),
    });
    const data = await response.json();
    if (response.status === 200) {
      document.querySelector(".reset__otp_expired").classList.add("hidden");
      setShowError(false);
      //   document.querySelector(".reset__otp_success").classList.remove("hidden");
    } else {
      const errorContainer = document.getElementById("error-container");
      errorContainer.innerHTML = "";
      const errorParagraph = document.createElement("p");
      errorParagraph.textContent = data.message;
      errorContainer.appendChild(errorParagraph);

      setShowError(true);
    }
  };
  function validateConfirmPassword(event) {
    setConfirmPassword(event.target.value);

    if (event.target.value !== password) {
      document
        .querySelector(".confirmPassword-error")
        .classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".confirmPassword-error").classList.add("hidden");
    }
  }
  const togglePasswordVisibility = (type) => {
    if (type === "normal") setShowPassword(!showPassword);
    else setShowConfirmPassword(!showConfirmPassword);
  };
  const handleLoginSubmit = async (e) => {
    try {
      setSaveChanges(true);
      const params = new URLSearchParams(window.location.search);
      const email = params.get("email");
      e.preventDefault();
      setButtonEnable(true);
      const url =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_FRONTEND_URL
          : process.env.REACT_APP_LOCAL_URL;
      const response = await fetch(`${url}/admin/user/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, newPassword: password, otp: otp }),
      });
      const data = await response.json();
      if (data.message === "OTP expired" || data.message === "Invalid OTP") {
        toast.error(data.message);
        setSaveChanges(false);
        document
          .querySelector(".reset__otp_expired")
          .classList.remove("hidden");
      }
      if (response.status === 200) {
        navigate("/login");
      } else {
        toast.error(data.message);
        setSaveChanges(false);
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  return (
    <section className="login__section">
      <div className="login__box">
        <div className="login__box__image">
          <img src={Logo} alt="logo"></img>
        </div>
        <div className="login__box__form">
          <div className="login__box__form__details">
            <h2>Verify Account</h2>
            <p>Enter your details!</p>
          </div>
          <div
            id="error-container"
            style={{ display: `${showError ? "block" : "none"}` }}
          ></div>
          <div className="reset__box__form__fields">
            <div>
              <p className="reset__box__form__fields__email">
                OTP
                <Popup
                  className="warning-popup"
                  trigger={
                    <img src={Warning} alt="" className="otp-error hidden" />
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Enter Valid OTP!
                </Popup>
              </p>
              <input
                type="number"
                placeholder="OTP"
                value={otp}
                onChange={otpValid}
              ></input>
            </div>
            <button
              className="reset__otp_expired hidden"
              onClick={handleRegenerateOTP}
            >
              OTP Expired? Resend!
            </button>
            <div>
              <p className="reset__box__form__fields__email">
                Password
                <Popup
                  className="warning-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="password-error hidden"
                    />
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Enter Valid Password!
                </Popup>
              </p>
              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={validatePassword}
                ></input>
                <button
                  className="show_password"
                  onClick={() => {
                    togglePasswordVisibility("normal");
                  }}
                >
                  <img
                    src={showPassword ? Eye_Off : Eye_On}
                    alt="show_password"
                  ></img>
                </button>
              </div>
            </div>
            <div>
              <p className="reset__box__form__fields__email">
                Confirm Password
                <Popup
                  className="warning-popup"
                  trigger={
                    <img
                      src={Warning}
                      alt=""
                      className="confirmPassword-error hidden"
                    />
                  }
                  on="hover"
                  position="top center"
                  arrow={false}
                >
                  Passwords Do Not Match
                </Popup>
              </p>
              <div>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={validateConfirmPassword}
                ></input>
                <button
                  className="show_password"
                  onClick={() => {
                    togglePasswordVisibility("confirm");
                  }}
                >
                  <img
                    src={showConfirmPassword ? Eye_Off : Eye_On}
                    alt="show_password"
                  ></img>
                </button>
              </div>
            </div>
          </div>
          <div className="signup__box__form__submit">
            <button
              onClick={handleLoginSubmit}
              className={`signup__submit ${
                buttonEnable ? "signup_button_disabled" : ""
              }`}
            >
              {saveChanges ? (
                <TailSpin
                  color={"#0C1D58"}
                  visible={saveChanges}
                  // css={override}
                  height="30"
                  width="50"
                  radius="1"
                />
              ) : (
                "Verify Account"
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
}

export default VerifyAccount;
