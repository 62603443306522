import "./../stylesheets/email.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { useNavigate } from "react-router-dom";
import DropdownComponent from "./dropdown.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Email({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [sendMethod, setSendMethod] = useState("");
  const [type, setType] = useState("");
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [smsBody, setSmsBody] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/users`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setUsers(data.users);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getUsers();
    const getAdmins = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/admins`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setAdmins(data.users);
          setLoading(false);
        }
        setLoading(false);
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAdmins();
  }, [logtail]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleSendMethodChange = (e) => {
    setSendMethod(e.target.value);
  };
  const handleType = (e) => {
    setType(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;

        const response = await fetch(`${url}/admin/email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            type: type,
            sendMethod: sendMethod,
            users: selectedOptions,
            emailSubject: emailSubject,
            emailBody: emailBody,
            smsBody: smsBody,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          toast.success(data.msg);

        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
      setSaveChanges(false);
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar selected={`Email`} sidebar={sidebar} setSidebar={setSidebar} />
      <div className="email">
        <Navbar section={"Email"} sidebar={sidebar} setSidebar={setSidebar} />
        <section className="email__section">
          <div className="email_selection">
            <div className="section">
              <label>
                Select Type:
                <select value={sendMethod} onChange={handleSendMethodChange}>
                  <option value="">Select Method</option>
                  <option value="sms">Send SMS</option>
                  <option value="email">Send Email</option>
                </select>
              </label>
            </div>
          </div>
          <div className="email_selection">
            <div className="section">
              <label>
                Select User Type:
                <select value={type} onChange={handleType}>
                  <option value="">Select Method</option>
                  <option value="admins">Admins</option>
                  <option value="users">Users</option>
                </select>
              </label>
            </div>
          </div>
          <div className="receipients__div">
            <div className="receipients">
              {type === "users" && (
                <label>
                  Select Users:
                  {users.length >= 1 && (
                    <DropdownComponent
                      users={users}
                      type={sendMethod}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  )}
                </label>
              )}
              {type === "admins" && (
                <label>
                  Select Admins:
                  {admins.length >= 1 && (
                    <DropdownComponent
                      users={admins}
                      type={sendMethod}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  )}
                </label>
              )}
            </div>
          </div>
          {sendMethod === "email" && (
            <div className="email__div">
              <div className="email__subject">
                <h2>Email Subject</h2>
                <input
                  type="text"
                  placeholder="Email Subject"
                  value={emailSubject}
                  onChange={(e) => {
                    setEmailSubject(e.target.value);
                  }}
                />
              </div>
              <div className="email__body">
                <h2>Email Body</h2>
                <textarea
                  type="text"
                  placeholder="Email Body"
                  value={emailBody}
                  onChange={(e) => {
                    setEmailBody(e.target.value);
                  }}
                />
              </div>
              {admin &&
                admin?.admin?.permissions?.permissions?.emailSubscribers && (
                  <div className="email__button">
                    <button onClick={handleSubmit}>
                      {saveChanges ? (
                        <TailSpin
                          color={"#0C1D58"}
                          visible={saveChanges}
                          // css={override}
                          height="30"
                          width="50"
                          radius="1"
                        />
                      ) : (
                        "Send Email"
                      )}
                    </button>
                  </div>
                )}
            </div>
          )}
          {sendMethod === "sms" && (
            <div className="email__div">
              <div className="email__body">
                <h2>SMS Content</h2>
                <textarea
                  type="text"
                  placeholder="SMS Content"
                  value={smsBody}
                  onChange={(e) => {
                    setSmsBody(e.target.value);
                  }}
                />
              </div>
              {admin &&
                admin?.admin?.permissions?.permissions?.emailSubscribers && (
                  <div className="email__button">
                    <button onClick={handleSubmit}>
                      {saveChanges ? (
                        <TailSpin
                          color={"#0C1D58"}
                          visible={saveChanges}
                          // css={override}
                          height="30"
                          width="50"
                          radius="1"
                        />
                      ) : (
                        "Send SMS"
                      )}
                    </button>
                  </div>
                )}
            </div>
          )}
        </section>
      </div>
      <ToastContainer />
    </>
  );
}

export default Email;
