import "./../stylesheets/statement.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import "./../stylesheets/roles.css";
import Sidebar from "./sidebar.js";
import Dropdown from "./dropdownMain.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Statement({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const loanTypes = [
    { name: "Personal Loan" },
    { name: "SME Loan" },
    { name: "Agricultural Loan" },
    { name: "Educational Loan" },
    { name: " Other" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [publicService, setPublicService] = useState(null);
  const [loanType, setLoanType] = useState(null);
  const [employer, setEmployer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [employersList, setEmployersList] = useState([]);
  useEffect(() => {
    const getAllUsers = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/employers`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          setEmployersList(data.employers);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAllUsers();
  }, [logtail]);
  const [saveChanges, setSaveChanges] = useState(false);
  const handleReport = async (e) => {
    try {
      setSaveChanges(true);
      e.preventDefault();
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/loans/monthly`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            startDate: startDate,
            endDate: endDate,
            publicSerice: publicService,
            loanType: loanType.label,
            employer: employer.label,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          if (data.url) {
            window.open(data.url, "_blank");
            setSaveChanges(false);
          }
        } else {
          toast.error(data.message);
          setSaveChanges(false);
        }
      }
    } catch (error) {
      logtail.error(error);
      logtail.flush();
      toast.error(error.message);
      setSaveChanges(false);
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={`Statement`}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="statement">
        <Navbar
          section={`Statement`}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />
        <section className="statement__section">
          <div className="statement__heading">
            <h2>Generate Monthly Due Statement</h2>
            <p>Select options to include in the statement</p>
          </div>
          <div className="statement__form">
            <div className="statement__form__date">
              <h2>Date Range</h2>
              <div className="statement__form__date__div">
                <div>
                  <label>Start Date</label>
                  <input
                    type="date"
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    value={startDate}
                  />
                </div>
                <div>
                  <label>End Date</label>
                  <input
                    type="date"
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    value={endDate}
                  />
                </div>
              </div>
            </div>
            <div className="statement__form__public__service">
              <h2>Public Service</h2>
              <div className="statement__form__public__service__div">
                <div>
                  <input
                    type="radio"
                    checked={publicService === "Yes"}
                    onClick={() => {
                      setPublicService("Yes");
                    }}
                  />
                  <label
                    onClick={() => {
                      setPublicService("Yes");
                    }}
                  >
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    checked={publicService === "No"}
                    onClick={() => {
                      setPublicService("No");
                    }}
                  />
                  <label
                    onClick={() => {
                      setPublicService("No");
                    }}
                  >
                    No
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    checked={publicService === "Either"}
                    onClick={() => {
                      setPublicService("Either");
                    }}
                  />
                  <label
                    onClick={() => {
                      setPublicService("Either");
                    }}
                  >
                    Either
                  </label>
                </div>
              </div>
            </div>
            <div className="statement__form__loan__type">
              <h2>Loan Type</h2>
              <div className="statement__form__loan__type__div">
                <Dropdown
                  users={loanTypes}
                  setSelectedOptions={setLoanType}
                  selectedOptions={loanType}
                />
              </div>
            </div>
            <div className="statement__form__employer">
              <h2>Employer</h2>
              <div className="statement__form__employer__div">
                <Dropdown
                  users={employersList ? employersList : []}
                  setSelectedOptions={setEmployer}
                  selectedOptions={employer}
                />
              </div>
            </div>
            <div className="statement__form__button">
              <button className="signup__submit" onClick={handleReport}>
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Generate Statement"
                )}
              </button>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}
export default Statement;
