import "./../stylesheets/dashboard.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import Sidebar from "./sidebar.js";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Dashboard({ sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalLoans, setTotalLoans] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [totalProcessingFee, setTotalProcessingFee] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalInsuranceFee, setTotalInsuranceFee] = useState(0);
  const [totalPenalty, setTotalPenalty] = useState(0);
  const [paidLoan, setPaidLoan] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getDashboardData = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          console.log(url);
          const response = await fetch(`${url}/admin/dashboard`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          if (response.status === 403) {
            localStorage.removeItem("vitpay_data");
            window.location.href = "/login";
          }
          const data = await response.json();
          setTotalUsers(data.totalUsers);
          setTotalLoans(data.loanAmounts.loanGiven);
          setTotalRemaining(data.loanAmounts.loanReceivable.toFixed(2));
          setTotalProcessingFee(data.loanAmounts.totalProcessingFee.toFixed(2));
          setTotalInterest(data.loanAmounts.totalInterestFee.toFixed(2));
          setTotalInsuranceFee(data.loanAmounts.totalInsuranceFee.toFixed(2));
          setTotalPenalty(data.loanAmounts.totalPenaltyAmount.toFixed(2));
          setPaidLoan(data.loanAmounts.paidLoan.toFixed(2));
          setLoading(false);
        }
      } catch (error) {
        logtail.error("Error fetching dashboard data", error);
        logtail.flush();
        toast.error("Error fetching dashboard data");
        setLoading(false);
      }
    };
    getDashboardData();
  }, [logtail]);
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={"Dashboard"}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />
      <div className="dashboard">
        <Navbar
          section="Dashboard"
          sidebar={sidebar}
          setSidebar={setSidebar}
        ></Navbar>
        <div className="dashboard__section__middle">
          <div className="dashboard__total__users dashboard__card">
            <div>
              <h4>Total Users</h4>
              <h3>{totalUsers}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Loans Issued</h4>
              <h3>K{totalLoans}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Loans Receivable</h4>
              <h3>K{totalRemaining}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Paid Amount</h4>
              <h3>K{paidLoan}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Processing Fee</h4>
              <h3>K{totalProcessingFee}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Interest Fee</h4>
              <h3>K{totalInterest}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Insurance Fee</h4>
              <h3>K{totalInsuranceFee}</h3>
            </div>
          </div>
          <div className="dashboard__active__users dashboard__card">
            <div>
              <h4>Total Penalty Amount</h4>
              <h3>K{totalPenalty}</h3>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Dashboard;
