import "./../stylesheets/signup.css";

import React, { useState, useEffect, useMemo } from "react";

import Navbar from "./navbar.js";

import Sidebar from "./sidebar.js";
// import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import Warning from "./../images/warning.svg";
import RolesDropdown from "./rolesDropdown.js";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function AddAccount({ user, setUser, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [buttonEnable, setButtonEnable] = useState(true);
  const [showError, setShowError] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  useEffect(() => {
    const getRoles = async () => {
      try {
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/roles`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();
          if (response.status === 200) {
            setRoles(data.roles);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
        logtail.error(error);
        logtail.flush();
      }
    };
    getRoles();
  }, [logtail]);
  const validateEmail = (event) => {
    setEmail(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(event.target.value)) {
      document.querySelector(".email-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".email-error").classList.add("hidden");
    }
  };

  function validateFirstName(event) {
    setFirstName(event.target.value);
    const nameRegex = /^[a-zA-Z]+$/;

    if (!nameRegex.test(event.target.value)) {
      document.querySelector(".firstName-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".firstName-error").classList.add("hidden");
    }
  }
  function validateLastName(event) {
    setLastName(event.target.value);
    const nameRegex = /^[a-zA-Z]+$/;

    if (!nameRegex.test(event.target.value)) {
      document.querySelector(".lastName-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".lastName-error").classList.add("hidden");
    }
  }
  function validateMobileNumber(event) {
    setMobileNumber(event.target.value);
    const mobileNumberRegex = /^[0-9]+$/;
    if (!mobileNumberRegex.test(event.target.value)) {
      document.querySelector(".mobile-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".mobile-error").classList.add("hidden");
    }
  }

  function validateUserName(event) {
    setUserName(event.target.value);
    if (event.target.value.length < 5) {
      document.querySelector(".userName-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".userName-error").classList.add("hidden");
    }
  }

  function isEmailValid(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isNameValid(name) {
    const nameRegex = /^[a-zA-Z ]+$/;
    return nameRegex.test(name);
  }

  function isMobileNumberValid(mobileNumber) {
    const mobileRegex = /^[0-9]{10,15}$/;
    return mobileRegex.test(mobileNumber);
  }

  function isUserNameValid(userName) {
    return userName.length > 5;
  }

  function isOccupationValid(occupation) {
    return occupation.value !== "";
  }

  useEffect(() => {
    if (
      isUserNameValid(userName) &&
      isOccupationValid(role) &&
      isMobileNumberValid(mobileNumber) &&
      isNameValid(lastName) &&
      isNameValid(firstName) &&
      isEmailValid(email)
    ) {
      setButtonEnable(false);
      setShowError(false);
    }
  }, [firstName, lastName, email, mobileNumber, role, userName]);
  const handleLoginSubmit = async (e) => {
    try {
      e.preventDefault();
      setSaveChanges(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      setButtonEnable(false);

      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobileNumber: mobileNumber,
            userName: userName,
            role: role.value,
          }),
        });
        const data = await response.json();
        if (response.status === 200) {
          navigate("/admins");
        } else if (response.status === 400) {
          setSaveChanges(false);
          toast.error(data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      logtail.error(error);
      logtail.flush();
    }
  };
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar
        selected={"Settings"}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />

      <section className="signup__section">
        <Navbar
          section={"Settings"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />

        <div className="signup__box">
          <div className="signup__box__form">
            <div className="signup__box__form__details">
              <h2>Create A New Admin</h2>
              <p>Enter all the Details to create a new Admin</p>
            </div>
            <div
              id="error-container"
              style={{ display: `${showError ? "block" : "none"}` }}
            ></div>

            <div className="signup__box__form__fields">
              <div className="signup__box__form__fields__div">
                <p className="signup__box__form__fields__email">
                  Email Address
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="email-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Email!
                  </Popup>
                </p>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={validateEmail}
                ></input>
              </div>
              <div className="signup__box__form__fields__div">
                <p className="signup__box__form__fields__email">
                  First Name
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="firstName-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid First Name!
                  </Popup>
                </p>
                <input
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={validateFirstName}
                ></input>
              </div>
              <div className="signup__box__form__fields__div">
                <p className="signup__box__form__fields__email">
                  Last Name
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="lastName-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Last Name!
                  </Popup>
                </p>
                <input
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={validateLastName}
                ></input>
              </div>
              <div className="signup__box__form__fields__div">
                <p className="signup__box__form__fields__email">
                  Mobile Number
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="mobile-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Mobile Number!
                  </Popup>
                </p>
                <input
                  type="number"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={validateMobileNumber}
                ></input>
              </div>
              <div className="signup__box__form__fields__div">
                <p className="signup__box__form__fields__email">
                  Username
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="userName-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Username!
                  </Popup>
                </p>
                <input
                  type="text"
                  placeholder="Username"
                  value={userName}
                  onChange={validateUserName}
                ></input>
              </div>

              {roles && (
                <RolesDropdown
                  users={roles}
                  setSelectedOptions={setRole}
                  selectedOptions={role}
                />
              )}
            </div>
            <div className="signup__box__form__submit">
              <button
                disabled={buttonEnable}
                onClick={handleLoginSubmit}
                className={`signup__submit ${
                  buttonEnable ? "signup_button_disabled" : ""
                }`}
              >
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Create User"
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default AddAccount;
