import "./../stylesheets/users.css";
import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./navbar.js";
import "./../stylesheets/roles.css";
import Sidebar from "./sidebar.js";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Roles({ admin, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState(null);
  useEffect(() => {
    const getAllTransactions = async () => {
      try {
        setLoading(true);
        const tokenData = localStorage.getItem("vitpay_data");
        const token = tokenData ? JSON.parse(tokenData).token : null;
        //
        if (token) {
          const url =
            process.env.NODE_ENV === "production"
              ? process.env.REACT_APP_FRONTEND_URL
              : process.env.REACT_APP_LOCAL_URL;
          const response = await fetch(`${url}/admin/roles`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
          });
          const data = await response.json();

          setRoles(data.roles);
          setLoading(false);
        }
      } catch (error) {
        logtail.error(error);
        logtail.flush();
        toast.error(error.message);
        setLoading(false);
      }
    };
    getAllTransactions();
  }, [logtail]);
  if (loading) {
    return (
      <div className="loader">
        <TailSpin
          color={"#0C1D58"}
          visible={true}
          height="70"
          width="70"
          radius="1"
        />
      </div>
    );
  }
  return (
    <>
      <Sidebar selected={`Roles`} sidebar={sidebar} setSidebar={setSidebar} />
      <div className="transactions">
        <Navbar section={`Roles`} sidebar={sidebar} setSidebar={setSidebar} />
        <section className="users__section">
          {admin && admin.admin.permissions.permissions.setUpRole && (
            <div className="role_button">
              <button
                onClick={() => {
                  navigate("/roles/create");
                }}
              >
                Add A Role
              </button>
            </div>
          )}
          <div className="roles__history">
            <div className="roles__history__wrapper">
              <table className="roles__history__table">
                <thead className="roles__history__heading">
                  <tr className="roles__history__heading--item">
                    <th>Sr.No</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="roles__history__items">
                  {roles &&
                    roles.map((user, index) => {
                      return (
                        <tr className="roles__history--item" key={index}>
                          <td>{index + 1}</td>
                          <td>{user.name}</td>

                          <td>
                            {new Date(
                              user.createdAt?.split("T")[0]
                            )?.toLocaleDateString("en-GB")}
                          </td>
                          {admin &&
                            admin.admin.permissions.permissions.setUpRole && (
                              <td>
                                <button data-id={user._id}>Delete</button>
                              </td>
                            )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}
export default Roles;
