import React from "react";
import Select from "react-select";

function DropdownComponent(props) {
  const { users, type, setSelectedOptions, selectedOptions   } =
    props;
  // Example array of users
  // Format users array for React Select options
  const allOption = { value: "all", label: "Select All" };

  const options = [
    allOption,
    ...users.map((user) => ({
      value: user._id,
      label: `${user.firstName} ${user.surName} (${
        type === "email" ? user.email : user.phoneNumber
      })`,
    })),
  ];

  const handleChange = (selectedOptions) => {
    if (selectedOptions?.some((option) => option.value === "all")) {
      setSelectedOptions(options.filter((option) => option.value !== "all"));
    } else {
      setSelectedOptions(selectedOptions);
    }
  };

  return (
    <div className="dropdown__div">
      <Select
        className="dropdown"
        classNamePrefix="dropdown"
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        placeholder="Select..."
        isMulti={true}
      />
      {/* {selectedOptions.length > 0 && (
        <div className="selected-users">
          {selectedOptions.map((option) => (
            <p key={option.value}>{option.label}</p>
          ))}
        </div>
      )} */}
    </div>
  );
}

export default DropdownComponent;
