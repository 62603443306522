import "./../stylesheets/login.css";

import React, { useState, useEffect, useMemo } from "react";

import Eye_On from "./../images/eye-outline.svg";
import Eye_Off from "./../images/eye-off-outline.svg";
import Navbar from "./navbar.js";
import Popup from "reactjs-popup";
import Warning from "./../images/warning.svg";
import Sidebar from "./sidebar.js";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { Logtail } from "@logtail/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function ChangePassword({ user, setUser, sidebar, setSidebar }) {
  const logtail = useMemo(
    () => new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN),
    []
  );
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [buttonEnable, setButtonEnable] = useState(true);
  function validateOldPassword(event) {
    setOldPassword(event.target.value);
    const minLength = 8;
    const maxLength = 128;

    if (event.target.value.length < minLength) {
      document.querySelector(".old-password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else if (event.target.value.length > maxLength) {
      document.querySelector(".old-password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".old-password-error").classList.add("hidden");
    }
  }
  function validatePassword(event) {
    setPassword(event.target.value);
    const minLength = 8;
    const maxLength = 128;

    if (event.target.value.length < minLength) {
      document.querySelector(".password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else if (event.target.value.length > maxLength) {
      document.querySelector(".password-error").classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".password-error").classList.add("hidden");
    }
  }
  function validateConfirmPassword(event) {
    setConfirmPassword(event.target.value);

    if (event.target.value !== password) {
      document
        .querySelector(".confirmPassword-error")
        .classList.remove("hidden");
      setButtonEnable(true);
    } else {
      document.querySelector(".confirmPassword-error").classList.add("hidden");
    }
  }
  const togglePasswordVisibility = (type) => {
    if (type === "normal") setShowPassword(!showPassword);
    else if (type === "confirm") setShowConfirmPassword(!showConfirmPassword);
    else setShowOldPassword(!showOldPassword);
  };
  useEffect(() => {
    const isValidOldPassword = (password) => {
      const minLength = 8;
      const maxLength = 128;
      return password.length >= minLength && password.length <= maxLength;
    };
    const isValidPassword = (password) => {
      const minLength = 8;
      const maxLength = 128;
      return password.length >= minLength && password.length <= maxLength;
    };
    const isValidConfirmPassword = (confirmPassword) => {
      return confirmPassword === password;
    };
    if (
      isValidOldPassword(oldPassword) &&
      isValidPassword(password) &&
      isValidConfirmPassword(confirmPassword)
    ) {
      setButtonEnable(false);
    }
  }, [oldPassword, password, confirmPassword]);
  const [saveChanges, setSaveChanges] = useState(false);
  const handleLoginSubmit = async (e) => {
    try {
      setSaveChanges(true);
      e.preventDefault();
      setButtonEnable(true);
      const tokenData = localStorage.getItem("vitpay_data");
      const token = tokenData ? JSON.parse(tokenData).token : null;
      if (token) {
        const url =
          process.env.NODE_ENV === "production"
            ? process.env.REACT_APP_FRONTEND_URL
            : process.env.REACT_APP_LOCAL_URL;
        const response = await fetch(`${url}/admin/user/password/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: password,
          }),
        });
        const data = await response.json();

        if (response.status === 200) {
          localStorage.removeItem("vitpay_data");
          navigate("/login");
        } else {
          toast.error(data.message);
          setSaveChanges(false);
          // setButtonEnable(true)
        }
      }
    } catch (error) {
      logtail.error("Error in updating password", error);
      logtail.flush();
      toast.error("Error in updating password");
      setSaveChanges(false);
    }
  };
  return (
    <>
      <Sidebar
        selected={"Settings"}
        sidebar={sidebar}
        setSidebar={setSidebar}
      />

      <section className="updatePassword__section">
        <Navbar
          section={"Settings"}
          sidebar={sidebar}
          setSidebar={setSidebar}
        />

        <div className="updatePassword__box">
          <div className="login__box__form">
            <div className="login__box__form__details">
              <h2>Update Password</h2>
              <p>Enter your new password!</p>
            </div>
            <div className="reset__box__form__fields">
              <div>
                <p className="reset__box__form__fields__email">
                  Old Password
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="old-password-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Old Password!
                  </Popup>
                </p>
                <div>
                  <input
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={validateOldPassword}
                  ></input>
                  <button
                    className="show_password"
                    onClick={() => {
                      togglePasswordVisibility("old");
                    }}
                  >
                    <img
                      src={showOldPassword ? Eye_Off : Eye_On}
                      alt="show_password"
                    ></img>
                  </button>
                </div>
              </div>
              <div>
                <p className="reset__box__form__fields__email">
                  New Password
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="password-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Enter Valid Password!
                  </Popup>
                </p>
                <div>
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Old Password"
                    value={password}
                    onChange={validatePassword}
                  ></input>
                  <button
                    className="show_password"
                    onClick={() => {
                      togglePasswordVisibility("normal");
                    }}
                  >
                    <img
                      src={showPassword ? Eye_Off : Eye_On}
                      alt="show_password"
                    ></img>
                  </button>
                </div>
              </div>
              <div>
                <p className="reset__box__form__fields__email">
                  Confirm Password
                  <Popup
                    className="warning-popup"
                    trigger={
                      <img
                        src={Warning}
                        alt=""
                        className="confirmPassword-error hidden"
                      />
                    }
                    on="hover"
                    position="top center"
                    arrow={false}
                  >
                    Passwords Do Not Match
                  </Popup>
                </p>
                <div>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={validateConfirmPassword}
                  ></input>
                  <button
                    className="show_password"
                    onClick={() => {
                      togglePasswordVisibility("confirm");
                    }}
                  >
                    <img
                      src={showConfirmPassword ? Eye_Off : Eye_On}
                      alt="show_password"
                    ></img>
                  </button>
                </div>
              </div>
            </div>
            <div className="signup__box__form__submit">
              <button
                onClick={handleLoginSubmit}
                className={`signup__submit ${
                  buttonEnable ? "signup_button_disabled" : ""
                }`}
              >
                {saveChanges ? (
                  <TailSpin
                    color={"#0C1D58"}
                    visible={saveChanges}
                    // css={override}
                    height="30"
                    width="50"
                    radius="1"
                  />
                ) : (
                  "Update Password"
                )}
              </button>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default ChangePassword;
